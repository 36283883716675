import { useAccount, useNetwork, useSigner, useProvider } from "wagmi";

export default function useWallet() {
  const { address, isConnected, connector } = useAccount();
  const { chain } = useNetwork();
  const provider = useProvider({ chainId: chain?.id });
  const { data: signer } = useSigner();
  // console.log("chain", chain)
  return {
    account: address,
    active: isConnected,
    provider: provider ?? undefined,
    connector,
    chainId: chain?.id,
    signer: signer ?? undefined,
  };
}
