import { createClient } from "./utils";
import {
  ARBITRUM,
  ARBITRUM_TESTNET,
  AVALANCHE,
  ETH_MAINNET,
  FANTOM,
  FANTOM_TESTNET,
  BASE,
  BASE_GOERLI,
} from "config/chains";

export const chainlinkClient = createClient(ETH_MAINNET, "chainLink");

export const arbitrumGraphClient = createClient(ARBITRUM, "stats");
export const arbitrumReferralsGraphClient = createClient(ARBITRUM, "referrals");
export const nissohGraphClient = createClient(ARBITRUM, "nissohVault");

export const avalancheGraphClient = createClient(AVALANCHE, "stats");
export const avalancheReferralsGraphClient = createClient(AVALANCHE, "referrals");
export const fantomTradingClient = createClient(FANTOM, "trading");
export const fantomTestnetTradingClient = createClient(FANTOM_TESTNET, "trading");
export const baseTradingClient = createClient(BASE, "trading");
export const baseGoerliTradingClient = createClient(BASE_GOERLI, "trading");

export function getGmxGraphClient(chainId: number) {
  if (chainId === ARBITRUM) {
    return arbitrumGraphClient;
  } else if (chainId === AVALANCHE) {
    return avalancheGraphClient;
  } else if (chainId === ARBITRUM_TESTNET) {
    return null;
  }

  throw new Error(`Unsupported chain ${chainId}`);
}

export function getDipTradingClient(chainId: number) {
  if (chainId === FANTOM) {
    return fantomTradingClient;
  } else if (chainId === FANTOM_TESTNET) {
    return fantomTestnetTradingClient;
  } else if (chainId === BASE) {
    return baseTradingClient;
  } else if (chainId === BASE_GOERLI) {
    return baseGoerliTradingClient;
  }
  throw new Error(`Unsupported chain ${chainId}`);
}
