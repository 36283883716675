import useSWR from "swr";
import { formatAmount, formatMoney } from "lib/numbers";
import { getTokenBySymbol } from "config/tokens";
import { getContract } from "config/contracts";
import { contractFetcher } from "lib/contracts";
import Pool from "abis/Pool.json";
import ERC20 from "abis/ERC20.json";
import { defaultTokenDecimals, defaultValueDecimals } from "lib/pool";
import LiquidityBootstrapping from "abis/LiquidityBootstrapping.json";
import { getUserInfoData } from "pages/LBP/services";
import { useDgtPairPrice, useDipPairPrice } from "./prices";
import DIPStakingAbi from "abis/DIPStaking.json";
import DIPMaster from "abis/DIPMaster.json";
import DaoStakingAbi from "abis/DaoStaking.json";

export function useDLPPrices (chainId, signer) {
  const poolAddress = getContract(chainId, "Pool");

  const SnrDLP = getTokenBySymbol(chainId, "SnrDLP");
  const MezzDLP = getTokenBySymbol(chainId, "MezzDLP");
  const JnrDLP = getTokenBySymbol(chainId, "JnrDLP");
  const { data: SnrDLPSupply } = useSWR([`Earn:SnrDLPSupply`, chainId, SnrDLP.address, "totalSupply"], {
    fetcher: contractFetcher(signer, ERC20),
  });

  const { data: MezzDLPSupply } = useSWR([`Earn:MezzDLPSupply`, chainId, MezzDLP.address, "totalSupply"], {
    fetcher: contractFetcher(signer, ERC20),
  });

  const { data: JnrDLPSupply } = useSWR([`Earn:JnrDLPSupply`, chainId, JnrDLP.address, "totalSupply"], {
    fetcher: contractFetcher(signer, ERC20),
  });

  const { data: SnrTrancheValue } = useSWR(
    [`Earn:SnrTrancheValue`, chainId, poolAddress, "getTrancheValue", SnrDLP.address, false],
    {
      fetcher: contractFetcher(signer, Pool),
    }
  );

  const { data: MezzTrancheValue } = useSWR(
    [`Earn:MezzTrancheValue`, chainId, poolAddress, "getTrancheValue", MezzDLP.address, true],
    {
      fetcher: contractFetcher(signer, Pool),
    }
  );

  const { data: JnrTrancheValue } = useSWR(
    [`Earn:JnrTrancheValue`, chainId, poolAddress, "getTrancheValue", JnrDLP.address, true],
    {
      fetcher: contractFetcher(signer, Pool),
    }
  );
  return {
    snrDLPPrice: formatAmount(SnrTrancheValue, defaultValueDecimals) / formatAmount(SnrDLPSupply, defaultTokenDecimals),
    mezzDLPPrice:
      formatAmount(MezzTrancheValue, defaultValueDecimals) / formatAmount(MezzDLPSupply, defaultTokenDecimals),
    jnrDLPPrice: formatAmount(JnrTrancheValue, defaultValueDecimals) / formatAmount(JnrDLPSupply, defaultTokenDecimals),
  };
}

export function useLbpUserInfo (chainId, active, signer, account) {
  const { data: snrLBP } = useSWR(active && [`LBP:SnrUserInfo`, chainId, getContract(chainId, "SnrLBP"), "userInfo"], {
    fetcher: contractFetcher(signer, LiquidityBootstrapping, [account]),
  });

  const { data: mezzLBP } = useSWR(
    active && [`LBP:MezzUserInfo`, chainId, getContract(chainId, "MezzLBP"), "userInfo"],
    {
      fetcher: contractFetcher(signer, LiquidityBootstrapping, [account]),
    }
  );

  const { data: jnrLBP } = useSWR(active && [`LBP:JnrUserInfo`, chainId, getContract(chainId, "JnrLBP"), "userInfo"], {
    fetcher: contractFetcher(signer, LiquidityBootstrapping, [account]),
  });
  const { data: dipEthLBP } = useSWR(
    active && [`LBP:DIPETHUserInfo`, chainId, getContract(chainId, "DIP_ETHLBP"), "userInfo"],
    {
      fetcher: contractFetcher(signer, LiquidityBootstrapping, [account]),
    }
  );

  return {
    snr: snrLBP ? getUserInfoData(snrLBP) : { amount: 0 },
    mezz: mezzLBP ? getUserInfoData(mezzLBP) : { amount: 0 },
    jnr: jnrLBP ? getUserInfoData(jnrLBP) : { amount: 0 },
    dipEth: dipEthLBP ? getUserInfoData(dipEthLBP) : { amount: 0 },
  };
}

export function useStakingUserInfo (chainId, active, signer, account) {
  const { data: dipStaking } = useSWR(active && [`Staking:DIPStakingUserInfo`, chainId, getContract(chainId, "DIPStaking"), "userInfo"], {
    fetcher: contractFetcher(signer, DIPStakingAbi, [account]),
  });

  const { data: dgtStaking } = useSWR(active && [`Staking:DGTStakingUserInfo`, chainId, getContract(chainId, "DGTStaking"), "userInfo"], {
    fetcher: contractFetcher(signer, DIPStakingAbi, [account]),
  });
  const { data: daoStaking } = useSWR(active && [`Staking:DaoStakingUserInfo`, chainId, getContract(chainId, "DaoStaking"), "userInfo"], {
    fetcher: contractFetcher(signer, DIPStakingAbi, [account]),
  });
  return {
    dipStaking: dipStaking ? { amount: formatAmount(dipStaking.amount, defaultTokenDecimals, 6) } : { amount: 0 },
    dgtStaking: dgtStaking ? { amount: formatAmount(dgtStaking.amount, defaultTokenDecimals, 6) } : { amount: 0 },
    daoStaking: daoStaking ? { amount: formatAmount(daoStaking.amount, defaultTokenDecimals, 6) } : { amount: 0 },
  };
}

export function useEarnPoolUserInfo (chainId, active, signer, account) {
  const { data: snrPool } = useSWR(
    active && ["Earn:SnrUserInfo", chainId, getContract(chainId, "DIPMaster"), "userInfo"],
    {
      fetcher: contractFetcher(signer, DIPMaster, [0, account]),
    }
  );

  const { data: mezzPool } = useSWR(
    active && ["Earn:MezzUserInfo", chainId, getContract(chainId, "DIPMaster"), "userInfo"],
    {
      fetcher: contractFetcher(signer, DIPMaster, [1, account]),
    }
  );

  const { data: jnrPool } = useSWR(
    active && ["Earn:JnrUserInfo", chainId, getContract(chainId, "DIPMaster"), "userInfo"],
    {
      fetcher: contractFetcher(signer, DIPMaster, [2, account]),
    }
  );
  const { data: dipEthPool } = useSWR(
    active && ["Earn:DIPETHUserInfo", chainId, getContract(chainId, "DIPMaster"), "userInfo"],
    {
      fetcher: contractFetcher(signer, DIPMaster, [3, account]),
    }
  );
  return {
    snr: snrPool ? getUserInfoData(snrPool) : { amount: 0 },
    mezz: mezzPool ? getUserInfoData(mezzPool) : { amount: 0 },
    jnr: jnrPool ? getUserInfoData(jnrPool) : { amount: 0 },
    dipEth: dipEthPool ? getUserInfoData(dipEthPool) : { amount: 0 },
  };
}

export function useDipEthLBPInfo (chainId, signer) {
  const { data: dipEthLBPPoolData } = useSWR([`DIP_ETHLBP:info`, chainId, getContract(chainId, "DIP_ETHLBP"), "info"], {
    fetcher: contractFetcher(signer, LiquidityBootstrapping),
  });
  return dipEthLBPPoolData;
}

export function useDipStakingInfo (chainId, signer) {
  const dipPairPrice = useDipPairPrice(chainId, signer);
  const dlpPrice = useDLPPrices(chainId, signer);

  const dipStakingAddr = getContract(chainId, "DIPStaking");
  const stakeToken = getTokenBySymbol(chainId, "DIP");

  const { data: totalDeposit } = useSWR(
    [`DIPStaking:SnrBalance$`, chainId, stakeToken.address, "balanceOf", dipStakingAddr],
    {
      fetcher: contractFetcher(signer, ERC20),
    }
  );
  const { data: rewardsPerSecond } = useSWR([`DIPStaking:rewardPerS`, chainId, dipStakingAddr, "rewardsPerSecond"], {
    fetcher: contractFetcher(signer, DIPStakingAbi),
  });

  const perDay = formatAmount(rewardsPerSecond, defaultTokenDecimals, 10) * 60 * 60 * 24;
  const totalDepositAmount = totalDeposit ? formatAmount(totalDeposit, defaultTokenDecimals, 4, true) : undefined;
  const totalDepositValue =
    totalDeposit && dipPairPrice?.dipPrice
      ? dipPairPrice?.dipPrice * formatAmount(totalDeposit, defaultTokenDecimals, 6)
      : undefined;

  const apr = totalDepositValue ? ((dlpPrice.snrDLPPrice * perDay * 365) / totalDepositValue) * 100 : undefined;
  return { apr, perDay, totalDepositAmount, totalDepositValue, dlpPrice, dipPairPrice };
}


export function useDaoStakingInfo (chainId, signer) {
  const dgtPairPrice = useDgtPairPrice(chainId, signer);

  const daoStakingAddr = getContract(chainId, "DaoStaking");
  const stakeToken = getTokenBySymbol(chainId, "DIP");

  const { data: totalDeposit } = useSWR(
    [`DaoStaking:DIPBalance$`, chainId, stakeToken.address, "balanceOf", daoStakingAddr],
    {
      fetcher: contractFetcher(signer, ERC20),
    }
  );
  const { data: rewardsPerSecond } = useSWR([`DaoStaking:rewardPerS`, chainId, daoStakingAddr, "rewardPerSecond"], {
    fetcher: contractFetcher(signer, DaoStakingAbi),
  });

  const perDay = formatAmount(rewardsPerSecond, defaultTokenDecimals, 10) * 60 * 60 * 24;
  const totalDepositAmount = totalDeposit ? formatAmount(totalDeposit, defaultTokenDecimals, 4, true) : undefined;
  const totalDepositValue =
    totalDeposit && dgtPairPrice?.dipPairPrice?.dipPrice
      ? dgtPairPrice?.dipPairPrice?.dipPrice * formatAmount(totalDeposit, defaultTokenDecimals, 6)
      : undefined;

  const apr = totalDepositValue ? ((dgtPairPrice?.dgtPrice * perDay * 365) / totalDepositValue) * 100 : undefined;
  return { apr, perDay, totalDepositAmount, totalDepositValue, dgtPairPrice };
}

export function useDgtStakingInfo (chainId, signer) {
  const dgtPairPrice = useDgtPairPrice(chainId, signer);
  const dlpPrice = useDLPPrices(chainId, signer);

  const dgtStakingAddr = getContract(chainId, "DGTStaking");
  const stakeToken = getTokenBySymbol(chainId, "DGT");

  const { data: totalDeposit } = useSWR(
    [`DGTStaking:SnrBalance$`, chainId, stakeToken.address, "balanceOf", dgtStakingAddr],
    {
      fetcher: contractFetcher(signer, ERC20),
    }
  );
  const { data: rewardsPerSecond } = useSWR([`DGTStaking:rewardPerS`, chainId, dgtStakingAddr, "rewardsPerSecond"], {
    fetcher: contractFetcher(signer, DIPStakingAbi),
  });

  const perDay = formatAmount(rewardsPerSecond, defaultTokenDecimals, 10) * 60 * 60 * 24;
  const totalDepositAmount = totalDeposit ? formatAmount(totalDeposit, defaultTokenDecimals, 4, true) : undefined;
  const totalDepositValue =
    totalDeposit && dgtPairPrice?.dgtPrice
      ? dgtPairPrice?.dgtPrice * formatAmount(totalDeposit, defaultTokenDecimals, 6)
      : undefined;

  const apr = totalDepositValue ? ((dlpPrice?.snrDLPPrice * perDay * 365) / totalDepositValue) * 100 : undefined;
  return { apr, perDay, totalDepositAmount, totalDepositValue, dgtPairPrice, dlpPrice };
}

export function useTreasuryInfo (chainId, signer) {
  const dipPairPrice = useDipPairPrice(chainId, signer);
  const dlpPrice = useDLPPrices(chainId, signer);
  const treasuryAddr = getContract(chainId, "Treasury");
  const dlp = getTokenBySymbol(chainId, "SnrDLP");
  const lp = getTokenBySymbol(chainId, "DIP/ETH LP");

  const { data: treasuryDLP } = useSWR([`Treasury:SnrBalance$`, chainId, dlp.address, "balanceOf", treasuryAddr], {
    fetcher: contractFetcher(signer, ERC20),
  });

  const { data: treasuryDipEthLP } = useSWR([`Treasury:SnrBalance$`, chainId, lp.address, "balanceOf", treasuryAddr], {
    fetcher: contractFetcher(signer, ERC20),
  });
  const dlpAmount = treasuryDLP ? formatAmount(treasuryDLP, defaultTokenDecimals, 6) : undefined;
  const lpAmount = treasuryDipEthLP ? formatAmount(treasuryDipEthLP, defaultTokenDecimals, 6) : undefined;
  const dlpValue = dlpAmount ? dlpAmount * dlpPrice.snrDLPPrice : undefined;
  const lpValue = lpAmount ? lpAmount * dipPairPrice.lpPrice : undefined;
  return {
    dlpAmount,
    lpAmount,
    dlpValue,
    lpValue,
    dipPairPrice,
    dlpPrice,
  };
}
