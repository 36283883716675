import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { Trans } from "@lingui/macro";

import { isHomeSite } from "lib/legacy";

import useWallet from "lib/wallets/useWallet";

import APRLabel from "../APRLabel/APRLabel";
import { HeaderLink } from "../Header/HeaderLink";
import { ARBITRUM, AVALANCHE } from "config/chains";
import { switchNetwork } from "lib/wallets";
import { useChainId } from "lib/chains";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { getIcon } from "config/icons";
import dipIcon from "img/DIP-token.png";
import dgtIcon from "img/dgt-white.png";

export default function TokenCard ({ showRedirectModal, redirectPopupTimestamp }) {
  const isHome = isHomeSite();
  const { chainId } = useChainId();
  const { active } = useWallet();

  const changeNetwork = useCallback(
    (network) => {
      if (network === chainId) {
        return;
      }
      if (!active) {
        setTimeout(() => {
          return switchNetwork(network, active);
        }, 500);
      } else {
        return switchNetwork(network, active);
      }
    },
    [chainId, active]
  );

  const BuyLink = ({ className, to, children, network }) => {
    if (isHome && showRedirectModal) {
      return (
        <HeaderLink
          to={to}
          className={className}
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          {children}
        </HeaderLink>
      );
    }

    return (
      <Link to={to} className={className} onClick={() => changeNetwork(network)}>
        {children}
      </Link>
    );
  };

  return (
    <div className="Home-token-card-options">
      <div className="Home-token-card-option">
        <div className="row aligns-center">
          <div className="col-12 col-md-8">
            <div className="Home-token-card-option-icon">
              <img src={dipIcon} width="40" alt="DIP Icons" /> DIP
            </div>
            <div className="Home-token-card-option-info">
              <div className="Home-token-card-option-title">
                <Trans>
                  DIP is the utility token and the backbone of DIP Exchange. DIP provides multiple benefits within the
                  platform, and can only be earned through staking and participation.
                </Trans>
              </div>
              {/* <div className="Home-token-card-option-apr">
            <Trans>Arbitrum APR:</Trans> <APRLabel chainId={ARBITRUM} label="gmxAprTotal" />,{" "}
            <Trans>Avalanche APR:</Trans> <APRLabel chainId={AVALANCHE} label="gmxAprTotal" key="AVALANCHE" />
          </div> */}
              {/* <div className="Home-token-card-option-action">
            <ExternalLink href="https://gmxio.gitbook.io/gmx/tokenomics" className="default-btn read-more">
              <Trans>Read more</Trans>
            </ExternalLink>
          </div> */}
            </div>
            <div className="a default-btn mt-20">Buy</div>
          </div>
          <div className="col-12 col-md-4 text-right">
            <img src={require("img/ecosystem_dip.png")} alt="dip"></img>
          </div>
        </div>
      </div>
      <div className="Home-token-card-option-reverse mt-20">
        <div className="row aligns-center">
          <div className="col-12 col-md-4">
            <img src={require("img/ecosystem_dgt.png")} alt="dgt"></img>
          </div>
          <div className="text-right col-12 col-md-8">
            <div className="Home-token-card-option-icon" style={{ justifyContent: "flex-end" }}>
              <img src={dgtIcon} width="40" alt="DGT Icon" /> DGT
            </div>
            <div className="Home-token-card-option-info">
              <div className="Home-token-card-option-title">
                <Trans>
                  DGT is the governance token of DIP Exchange. It can only be earned by burning or staking DIP. DIP
                  Exchange aims to be fully decentralized, with governance proposals made and decided by DGT holders.
                </Trans>
              </div>
            </div>
            <BuyLink to="/dao" className="default-btn mt-20" network={ARBITRUM}>
              <Trans>Earn</Trans>
            </BuyLink>
          </div>
          {/* <div className="Home-token-card-option-apr">
            <Trans>Arbitrum APR:</Trans> <APRLabel chainId={ARBITRUM} label="glpAprTotal" key="ARBITRUM" />,{" "}
            <Trans>Avalanche APR:</Trans> <APRLabel chainId={AVALANCHE} label="glpAprTotal" key="AVALANCHE" />
          </div> */}
          {/* <div className="Home-token-card-option-action">
            <a
              href="https://gmxio.gitbook.io/gmx/glp"
              target="_blank"
              rel="noreferrer"
              className="default-btn read-more"
            >
              <Trans>Read more</Trans>
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
}
