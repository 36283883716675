import { toast, ToastContent, ToastOptions } from "react-toastify";

const defaultOpt: ToastOptions = {
  // position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  // theme: "colored",
};
export const helperToast = {
  success: (content: ToastContent, opts?: ToastOptions) => {
    toast.dismiss();
    toast.success(content, opts ?? defaultOpt);
  },
  error: (content: ToastContent, opts?: ToastOptions) => {
    toast.dismiss();
    // toast.error(content, opts);
    toast.error(content, opts ?? defaultOpt);
  },
};
