import { ethers } from "ethers";
import { getContract } from "./contracts";
import {
  ARBITRUM,
  ARBITRUM_TESTNET,
  AVALANCHE,
  AVALANCHE_FUJI,
  MAINNET,
  TESTNET,
  FANTOM,
  FANTOM_TESTNET,
  BASE,
  BASE_GOERLI,
} from "./chains";
import { Token } from "domain/tokens";

export const placeHolderAddress = "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE";
export const TOKENS: { [chainId: number]: Token[] } = {
  [MAINNET]: [
    {
      name: "Bitcoin (BTCB)",
      symbol: "BTC",
      decimals: 18,
      address: "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
      coingeckoUrl: "https://www.coingecko.com/en/coins/binance-bitcoin",
      imageUrl: "https://assets.coingecko.com/coins/images/14108/small/Binance-bitcoin.png",
    },
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
      coingeckoUrl: "https://www.coingecko.com/en/coins/ethereum",
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png",
    },
    {
      name: "Binance Coin",
      symbol: "BNB",
      decimals: 18,
      address: ethers.constants.AddressZero,
      coingeckoUrl: "https://www.coingecko.com/en/coins/binance-coin",
      imageUrl: "https://assets.coingecko.com/coins/images/825/small/binance-coin-logo.png",
      isNative: true,
    },
    {
      name: "Wrapped Binance Coin",
      symbol: "WBNB",
      decimals: 18,
      address: getContract(MAINNET, "NATIVE_TOKEN"),
      isWrapped: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/binance-coin",
      imageUrl: "https://assets.coingecko.com/coins/images/825/small/binance-coin-logo.png",
      baseSymbol: "BNB",
    },
    {
      name: "USD Gambit",
      symbol: "USDG",
      decimals: 18,
      address: getContract(MAINNET, "USDG"),
      isUsdg: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/usd-gambit",
      imageUrl: "https://assets.coingecko.com/coins/images/15886/small/usdg-02.png",
    },
    {
      name: "Binance USD",
      symbol: "BUSD",
      decimals: 18,
      address: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
      isStable: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/binance-usd",
      imageUrl: "https://assets.coingecko.com/coins/images/9576/small/BUSD.png",
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      decimals: 18,
      address: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
      isStable: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/usd-coin",
      imageUrl: "https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png",
    },
    {
      name: "Tether",
      symbol: "USDT",
      decimals: 18,
      address: "0x55d398326f99059fF775485246999027B3197955",
      isStable: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/tether",
      imageUrl: "https://assets.coingecko.com/coins/images/325/small/Tether-logo.png",
    },
  ],
  [TESTNET]: [
    {
      name: "Bitcoin (BTCB)",
      symbol: "BTC",
      decimals: 8,
      address: "0xb19C12715134bee7c4b1Ca593ee9E430dABe7b56",
      imageUrl: "https://assets.coingecko.com/coins/images/26115/thumb/btcb.png?1655921693",
    },
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: "0x1958f7C067226c7C8Ac310Dc994D0cebAbfb2B02",
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    {
      name: "Binance Coin",
      symbol: "BNB",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      imageUrl: "https://assets.coingecko.com/coins/images/825/small/binance-coin-logo.png",
    },
    {
      name: "Wrapped Binance Coin",
      symbol: "WBNB",
      decimals: 18,
      address: "0x612777Eea37a44F7a95E3B101C39e1E2695fa6C2",
      isWrapped: true,
      baseSymbol: "BNB",
      imageUrl: "https://assets.coingecko.com/coins/images/825/small/binance-coin-logo.png",
    },
    {
      name: "USD Gambit",
      symbol: "USDG",
      decimals: 18,
      address: getContract(TESTNET, "USDG"),
      isUsdg: true,
      imageUrl: "https://assets.coingecko.com/coins/images/15886/small/usdg-02.png",
    },
    {
      name: "Binance USD",
      symbol: "BUSD",
      decimals: 18,
      address: "0x3F223C4E5ac67099CB695834b20cCd5E5D5AA9Ef",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/9576/small/BUSD.png",
    },
  ],
  [ARBITRUM_TESTNET]: [
    {
      name: "Bitcoin",
      symbol: "BTC",
      decimals: 8,
      address: "0x27960f9A322BE96A1535E6c19B3958e80E6a2670",
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png?1548822744",
    },
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    // https://github.com/OffchainLabs/arbitrum/blob/950c2f91b2e951cd3764394e0a73eac3797aecf3/packages/arb-ts/src/lib/networks.ts#L65
    {
      name: "Wrapped Ethereum",
      symbol: "WETH",
      decimals: 18,
      address: "0xB47e6A5f8b33b3F17603C83a0535A9dcD7E32681",
      isWrapped: true,
      baseSymbol: "ETH",
      imageUrl: "https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1628852295",
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      decimals: 6,
      address: "0xf0DCd4737A20ED33481A49De94C599944a3Ca737",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "Tether",
      symbol: "USDT",
      decimals: 6,
      address: "0x818ED84bA1927945b631016e0d402Db50cE8865f",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/325/small/Tether-logo.png",
    },
  ],
  [BASE]: [
    {
      name: "Ethereum",
      symbol: "ETH",
      baseSymbol: "ETH",
      displayDecimals: 2,
      decimals: 18,
      address: ethers.constants.AddressZero,
      wrappedAddress: "0x4200000000000000000000000000000000000006",
      placeHolderAddress: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
      isNative: true,
      isShortable: true,
      isPoolAssets: true,
      imageUrl: require("img/ETH.png"),
    },
    {
      name: "Wrapped Ethereum",
      symbol: "WETH",
      baseSymbol: "ETH",
      displayDecimals: 2,
      decimals: 18,
      address: "0x4200000000000000000000000000000000000006",
      isWrapped: true,
      imageUrl: require("img/ETH.png"),
    },
    {
      name: "Axelar Wrapped WBTC",
      symbol: "BTC",
      baseSymbol: "BTC",
      displayDecimals: 2,
      decimals: 8,
      address: "0x1a35EE4640b0A3B87705B0A4B45D227Ba60Ca2ad", // address for axlBTC
      isShortable: true,
      isPoolAssets: true,
      imageUrl: "https://docs.axelar.dev/images/assets/wbtc.svg",
    },

    {
      name: "USD Base Coin",
      symbol: "USDbC",
      baseSymbol: "USDC",
      displayDecimals: 2,
      decimals: 6,
      address: "0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA",
      isStable: true,
      isShortable: true,
      isPoolAssets: true,
      imageUrl: require("img/usdbc.png"),
    },

    // {
    //   name: "Tether",
    //   symbol: "USDT",
    //   baseSymbol: "USDT",
    //   decimals: 6,
    //   displayDecimals: 2,
    //   address: "0xcc1b99dDAc1a33c201a742A1851662E87BC7f22C",
    //   isStable: true,
    //   isWrapped: true,
    //   isPoolAssets: false,
    //   imageUrl: "https://assets.coingecko.com/coins/images/325/thumb/Tether-logo.png?1598003707",
    // },
    // {
    //   name: "Dai Stablecoin",
    //   symbol: "DAI",
    //   baseSymbol: "DAI",
    //   decimals: 18,
    //   displayDecimals: 2,
    //   address: "0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb",
    //   isStable: true,
    //   isPoolAssets: true,
    //   imageUrl: "https://assets.coingecko.com/coins/images/9956/thumb/4943.png?1636636734",
    // },
    {
      name: "Chainlink",
      symbol: "LINK",
      decimals: 18,
      address: "",
      imageUrl: "https://assets.coingecko.com/coins/images/877/thumb/chainlink-new-logo.png?1547034700",
    },
    {
      name: "Compound",
      symbol: "COMP",
      decimals: 18,
      address: "",
      imageUrl: "https://assets.coingecko.com/coins/images/10775/small/COMP.png?1592625425",
    },
    {
      name: "Senior DLP",
      symbol: "SnrDLP",
      decimals: 18,
      displayDecimals: 4,
      isLPToken: true,
      address: "0x3989A04Ba50cEd301e30778125e9bEa8E94B3500",
      imageUrl: require("img/ic_snr.png"),
    },
    {
      name: "Mezzanine DLP",
      symbol: "MezzDLP",
      decimals: 18,
      displayDecimals: 4,
      isLPToken: true,
      address: "0x9F75d096a3a27e4CF8039Ee3711c57D1eC78D038",
      imageUrl: require("img/ic_mezz.png"),
    },
    {
      name: "STG",
      symbol: "STG",
      decimals: 18,
      displayDecimals: 4,
      address: "",
      imageUrl: require("img/ic_stg.png"),
    },
    {
      name: "Junior DLP",
      symbol: "JnrDLP",
      decimals: 18,
      displayDecimals: 4,
      isLPToken: true,
      address: "0x8b69C883b67FEC564260A45785210A51C40FA479",
      imageUrl: require("img/ic_jnr.png"),
    },
    // {
    //   name: "STG DLP",
    //   symbol: "StgDLP",
    //   decimals: 18,
    //   isLPToken: true,
    //   address: "",
    //   // imageUrl: require("img/ic_jnr.png"),
    // },
    {
      name: "Uni V2",
      symbol: "DIP/ETH LP",
      decimals: 18,
      displayDecimals: 4,
      isLPToken: true,
      address: "0x0BE2EF4a1CC597dDd2a354505E08d7934802029d",
    },
    {
      name: "Uni V2",
      symbol: "ETH/USDC LP",
      decimals: 18,
      displayDecimals: 4,
      isLPToken: true,
      address: "0x41d160033C222E6f3722EC97379867324567d883",
    },
    {
      name: "Uni V2",
      symbol: "DGT/DIP LP",
      decimals: 18,
      displayDecimals: 4,
      isLPToken: true,
      // address: "0x7CFEbED9b170BA27225703Df119BaFFd247E0d27",
      address: "0x6178068e4C9001a63a128d852C87955D81196656"
    },
    {
      name: "DIP Token",
      symbol: "DIP",
      decimals: 18,
      displayDecimals: 4,
      address: "0xAdBa4694F5deAF3251d06dec38C86946F8952EFB",
      imageUrl: require("img/DIP-token.png"),
    },
    {
      name: "DGT Token",
      symbol: "DGT",
      decimals: 18,
      displayDecimals: 4,
      address: "0xE2986FD09A8c1d698EC50bD31F155e165C241D12",
      imageUrl: require("img/DGT-token.png"),
    },
    {
      name: "Experience Point",
      symbol: "EXP",
      decimals: 18,
      displayDecimals: 4,
      address: "0xddF6B186382177fE1F0A15656cf461aBB2dC2fa7",
      // imageUrl: require("img/DIP-token.png"),
    },
  ],
  [BASE_GOERLI]: [
    {
      name: "Ethereum",
      symbol: "ETH",
      baseSymbol: "ETH",
      displayDecimals: 2,
      decimals: 18,
      address: ethers.constants.AddressZero,
      wrappedAddress: "0x4200000000000000000000000000000000000006",
      placeHolderAddress: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
      isNative: true,
      isShortable: true,
      isPoolAssets: true,
      imageUrl: require("img/ETH.png"),
    },
    {
      name: "Wrapped Ethereum",
      symbol: "WETH",
      baseSymbol: "ETH",
      displayDecimals: 2,
      decimals: 18,
      address: "0x4200000000000000000000000000000000000006",
      isWrapped: true,
      imageUrl: require("img/ETH.png"),
    },
    {
      name: "Wrapped Bitcoin",
      symbol: "BTC",
      baseSymbol: "BTC",
      displayDecimals: 2,
      decimals: 8,
      address: "0xca460115d85E4d31187F4AD49781f6779EE1eC1f", //todo tmp address for axlBTC
      isShortable: true,
      isPoolAssets: true,
      imageUrl: require("img/BTC.png"),
    },

    {
      name: "USD Coin",
      symbol: "USDC",
      baseSymbol: "USDC",
      displayDecimals: 2,
      decimals: 18,
      address: "0xd54025119A3a8eCFB7397B87f702aa67948f37Dd",
      isStable: true,
      isShortable: true,
      isPoolAssets: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },

    // {
    //   name: "Tether",
    //   symbol: "USDT",
    //   baseSymbol: "USDT",
    //   decimals: 6,
    //   displayDecimals: 2,
    //   address: "0xcc1b99dDAc1a33c201a742A1851662E87BC7f22C",
    //   isStable: true,
    //   isWrapped: true,
    //   isPoolAssets: false,
    //   imageUrl: "https://assets.coingecko.com/coins/images/325/thumb/Tether-logo.png?1598003707",
    // },
    // {
    //   name: "Dai Stablecoin",
    //   symbol: "DAI",
    //   baseSymbol: "DAI",
    //   decimals: 18,
    //   displayDecimals: 2,
    //   address: "0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb",
    //   isStable: true,
    //   isPoolAssets: true,
    //   imageUrl: "https://assets.coingecko.com/coins/images/9956/thumb/4943.png?1636636734",
    // },
    {
      name: "Senior DLP",
      symbol: "SnrDLP",
      decimals: 18,
      isLPToken: true,
      displayDecimals: 4,
      address: "0xE9bdDDd6a5c0BA3dE9a6644b3a4D3eAbEdBa2378",
      imageUrl: require("img/ic_snr.png"),
    },
    {
      name: "Mezzanine DLP",
      symbol: "MezzDLP",
      decimals: 18,
      isLPToken: true,
      displayDecimals: 4,
      address: "0x095d01F51a8c396cA514661178FF8A8d5c6F4403",
      imageUrl: require("img/ic_mezz.png"),
    },
    {
      name: "STG",
      symbol: "STG",
      decimals: 18,
      displayDecimals: 4,
      address: "",
      imageUrl: require("img/ic_stg.png"),
    },
    {
      name: "Junior DLP",
      symbol: "JnrDLP",
      decimals: 18,
      displayDecimals: 4,
      isLPToken: true,
      address: "0x266A8E2Cd9c7cb3f6f7E5B821EFb94b59f64f73e",
      imageUrl: require("img/ic_jnr.png"),
    },
    {
      name: "STG DLP",
      symbol: "StgDLP",
      decimals: 18,
      displayDecimals: 4,
      isLPToken: true,
      address: "",
      // imageUrl: require("img/ic_jnr.png"),
    },
    {
      name: "Uni V2",
      symbol: "DIP/ETH LP",
      decimals: 18,
      displayDecimals: 4,
      isLPToken: true,
      address: "0xAd7C4c9bA86B9030afe02B3AB491A50A023A4d9e",
    },
    {
      name: "Uni V2",
      symbol: "ETH/USDC LP",
      decimals: 18,
      displayDecimals: 4,
      isLPToken: true,
      address: "0xEf74eE2E30A87169a95b9CF0878B3F7e62414E87",
    },
    {
      name: "Uni V2",
      symbol: "DGT/DIP LP",
      decimals: 18,
      displayDecimals: 4,
      isLPToken: true,
      address: "0x742f6F5720c51D5F7eA0e96fEC779584d91c7176",
    },
    {
      name: "DIP Token",
      symbol: "DIP",
      decimals: 18,
      displayDecimals: 4,
      address: "0x8a2FBc3b358B3822b4e80B59E6F05c40a42A577f",
      imageUrl: require("img/DIP-token.png"),
    },
    {
      name: "DGT Token",
      symbol: "DGT",
      decimals: 18,
      displayDecimals: 4,
      address: "0xFFF172b38cB0dcF6Ea4a2A1c491F800858fc94eE",
      imageUrl: require("img/DGT-token.png"),
    },
    {
      name: "Experience Point",
      symbol: "EXP",
      decimals: 18,
      displayDecimals: 4,
      address: "0x08362131943B40614d634Bfb98da88f53c72aD54",
      // imageUrl: require("img/DIP-token.png"),
    },
  ],

  [FANTOM]: [
    {
      name: "Fantom",
      symbol: "FTM",
      baseSymbol: "FTM",
      displayDecimals: 4,
      decimals: 18,
      address: ethers.constants.AddressZero,
      wrappedAddress: "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83",
      placeHolderAddress: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
      isNative: true,
      isShortable: true,
      isPoolAssets: true,
      imageUrl: "https://assets.coingecko.com/coins/images/4001/small/Fantom_round.png?1669652346",
    },
    {
      name: "Wrapped Fantom",
      symbol: "WFTM",
      baseSymbol: "FTM",
      displayDecimals: 4,
      decimals: 18,
      address: "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83",
      isWrapped: true,
      imageUrl: "https://assets.coingecko.com/coins/images/16036/small/Fantom.png?1622679930",
    },
    {
      name: "Wrapped Bitcoin",
      symbol: "BTC",
      baseSymbol: "BTC",
      displayDecimals: 2,
      decimals: 8,
      address: "0xf1648C50d2863f780c57849D812b4B7686031A3D",
      isShortable: true,
      isPoolAssets: true,
      imageUrl: require("img/BTC.png"),
    },
    {
      name: "Wrapped Ethereum",
      symbol: "ETH",
      baseSymbol: "ETH",
      displayDecimals: 2,
      decimals: 18,
      address: "0x695921034f0387eAc4e11620EE91b1b15A6A09fE",
      isShortable: true,
      isPoolAssets: true,
      imageUrl: require("img/ETH.png"),
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      baseSymbol: "USDC",
      decimals: 6,
      address: "0xF4683290Be51901dE2B881CD50Cf87467cbA25Cf",
      isStable: true,
      isShortable: true,
      isPoolAssets: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "USD Coin",
      symbol: "USDC_LZ",
      baseSymbol: "USDC",
      decimals: 6,
      address: "0xF4683290Be51901dE2B881CD50Cf87467cbA25Cf",
      isStable: true,
      isShortable: true,
      isWrapped: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "USD Coin",
      symbol: "USDC_MULTI",
      baseSymbol: "USDC",
      decimals: 6,
      address: "0x04068DA6C83AFCFA0e13ba15A6696662335D5B75",
      isStable: true,
      isShortable: true,
      isWrapped: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    // {
    //   name: "Tether",
    //   symbol: "USDT",
    //   baseSymbol: "USDT",
    //   decimals: 6,
    //   displayDecimals: 2,
    //   address: "0xcc1b99dDAc1a33c201a742A1851662E87BC7f22C",
    //   isStable: true,
    //   isWrapped: true,
    //   isPoolAssets: false,
    //   imageUrl: "https://assets.coingecko.com/coins/images/325/thumb/Tether-logo.png?1598003707",
    // },
    // {
    //   name: "Dai Stablecoin",
    //   symbol: "DAI",
    //   baseSymbol: "DAI",
    //   decimals: 18,
    //   displayDecimals: 2,
    //   address: "0x91a40C733c97a6e1BF876EaF9ed8c08102eB491f",
    //   isStable: true,
    //   isWrapped: true,
    //   isPoolAssets: true,
    //   imageUrl: "https://assets.coingecko.com/coins/images/9956/thumb/4943.png?1636636734",
    // },
    {
      name: "Senior DLP",
      symbol: "SnrDLP",
      decimals: 18,
      isLPToken: true,
      address: "",
      imageUrl: require("img/ic_snr.png"),
    },
    {
      name: "Mezzanine DLP",
      symbol: "MezzDLP",
      decimals: 18,
      isLPToken: true,
      address: "",
      imageUrl: require("img/ic_mezz.png"),
    },
    {
      name: "Junior DLP",
      symbol: "JnrDLP",
      decimals: 18,
      isLPToken: true,
      address: "",
      imageUrl: require("img/ic_jnr.png"),
    },
    {
      name: "STG DLP",
      symbol: "STG DLP",
      decimals: 18,
      isLPToken: true,
      address: "",
      // imageUrl: require("img/ic_jnr.png"),
    },
    {
      name: "Uni V2",
      symbol: "DIP/ETH LP",
      decimals: 18,
      isLPToken: true,
      address: "",
    },
    {
      name: "DIP Token",
      symbol: "DIP",
      decimals: 18,
      address: "",
      imageUrl: require("img/DIP-token.png"),
    },
  ],
  [FANTOM_TESTNET]: [
    {
      name: "Fantom",
      symbol: "FTM",
      baseSymbol: "FTM",
      displayDecimals: 4,
      decimals: 18,
      address: ethers.constants.AddressZero,
      wrappedAddress: "0xcb35Ef26f8B5C2dAcc3DcEB7571B3Bf842fd208c",
      placeHolderAddress: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
      isNative: true,
      isShortable: true,
      isPoolAssets: true,
      imageUrl: "https://assets.coingecko.com/coins/images/4001/small/Fantom_round.png?1669652346",
    },
    {
      name: "Wrapped Fantom",
      symbol: "WFTM",
      baseSymbol: "FTM",
      displayDecimals: 4,
      decimals: 18,
      address: "0xcb35Ef26f8B5C2dAcc3DcEB7571B3Bf842fd208c",
      isWrapped: true,
      imageUrl: "https://assets.coingecko.com/coins/images/16036/small/Fantom.png?1622679930",
    },
    {
      name: "Bitcoin (WBTC)",
      symbol: "BTC",
      baseSymbol: "BTC",
      displayDecimals: 2,
      decimals: 8,
      address: "0xB32FB1f93F29610a634F49AFF332e274C06358FE",
      isShortable: true,
      isPoolAssets: true,
      imageUrl: require("img/BTC.png"),
    },
    {
      name: "Wrapped Ethereum",
      symbol: "ETH",
      baseSymbol: "ETH",
      displayDecimals: 2,
      decimals: 18,
      address: "0x3CaccE07FA5A9726697526aA9d8D7Bb1fbE9768f",
      isShortable: true,
      isPoolAssets: true,
      imageUrl: require("img/ETH.png"),
    },

    // {
    //   name: "USD Coin",
    //   symbol: "USDC",
    //   decimals: 18,
    //   address: "0xF4683290Be51901dE2B881CD50Cf87467cbA25Cf",
    //   isStable: true,
    //   isShortable: true,
    //   isWrapped: true,
    //   isPoolAssets: true,
    //   imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    // },
    {
      name: "Tether",
      symbol: "USDT",
      baseSymbol: "USDT",
      decimals: 18,
      displayDecimals: 2,
      address: "0x6A580c925d28cBa015114E368e7F719E37d9fbaD",
      isStable: true,
      isPoolAssets: true,
      imageUrl: "https://assets.coingecko.com/coins/images/325/thumb/Tether-logo.png?1598003707",
    },
    {
      name: "Senior DLP",
      symbol: "SnrDLP",
      decimals: 18,
      isLPToken: true,
      address: "0xe6127f6b1a0B171f8a66b19DCFA944c74a5da5F8",
      imageUrl: require("img/ic_snr.png"),
    },
    {
      name: "Mezzanine DLP",
      symbol: "MezzDLP",
      decimals: 18,
      isLPToken: true,
      address: "0xedda73D166a69D455E1549182cE3A6d3aD3925EE",
      imageUrl: require("img/ic_mezz.png"),
    },
    {
      name: "Junior DLP",
      symbol: "JnrDLP",
      decimals: 18,
      isLPToken: true,
      address: "0x1730e15932763e0E3127653dF221e97070eDd5e4",
      imageUrl: require("img/ic_jnr.png"),
    },
    {
      name: "Uni V2",
      symbol: "DIP/ETH LP",
      decimals: 18,
      isLPToken: true,
      address: "0x4ce8cdE8Bcccf8532De5068b33857c8F91937Cfc",
    },
    {
      name: "DIP Token",
      symbol: "DIP",
      decimals: 18,
      address: "0x8F2E3D1207118b0d571B7575Ba3d08a750BbE88E",
    },
  ],
  [ARBITRUM]: [
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    {
      name: "Wrapped Ethereum",
      symbol: "WETH",
      decimals: 18,
      address: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      isWrapped: true,
      baseSymbol: "ETH",
      imageUrl: "https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1628852295",
    },
    {
      name: "Bitcoin (WBTC)",
      symbol: "BTC",
      baseSymbol: "BTC",
      decimals: 8,
      address: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png?1548822744",
    },
    {
      name: "Chainlink",
      symbol: "LINK",
      decimals: 18,
      address: "0xf97f4df75117a78c1A5a0DBb814Af92458539FB4",
      isStable: false,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/877/thumb/chainlink-new-logo.png?1547034700",
    },
    {
      name: "Uniswap",
      symbol: "UNI",
      decimals: 18,
      address: "0xFa7F8980b0f1E64A2062791cc3b0871572f1F7f0",
      isStable: false,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/12504/thumb/uniswap-uni.png?1600306604",
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      decimals: 6,
      address: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "Tether",
      symbol: "USDT",
      decimals: 6,
      address: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/325/thumb/Tether-logo.png?1598003707",
    },
    {
      name: "Dai",
      symbol: "DAI",
      decimals: 18,
      address: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/9956/thumb/4943.png?1636636734",
    },
    {
      name: "Frax",
      symbol: "FRAX",
      decimals: 18,
      address: "0x17FC002b466eEc40DaE837Fc4bE5c67993ddBd6F",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/13422/small/frax_logo.png?1608476506",
    },
    {
      name: "Magic Internet Money",
      symbol: "MIM",
      decimals: 18,
      address: "0xFEa7a6a0B346362BF88A9e4A88416B77a57D6c2A",
      isStable: true,
      isTempHidden: true,
      imageUrl: "https://assets.coingecko.com/coins/images/16786/small/mimlogopng.png",
    },
  ],
  [AVALANCHE]: [
    {
      name: "Avalanche",
      symbol: "AVAX",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png?1604021818",
    },
    {
      name: "Wrapped AVAX",
      symbol: "WAVAX",
      decimals: 18,
      address: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
      isWrapped: true,
      baseSymbol: "AVAX",
      imageUrl: "https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png?1604021818",
    },
    {
      name: "Ethereum (WETH.e)",
      symbol: "ETH",
      address: "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB",
      decimals: 18,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    {
      name: "Bitcoin (BTC.b)",
      symbol: "BTC",
      address: "0x152b9d0FdC40C096757F570A51E494bd4b943E50",
      decimals: 8,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/26115/thumb/btcb.png?1655921693",
    },
    {
      name: "Bitcoin (WBTC.e)",
      symbol: "WBTC",
      address: "0x50b7545627a5162F82A992c33b87aDc75187B218",
      decimals: 8,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png?1548822744",
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      address: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "USD Coin (USDC.e)",
      symbol: "USDC.e",
      address: "0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664",
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "Magic Internet Money",
      symbol: "MIM",
      address: "0x130966628846BFd36ff31a822705796e8cb8C18D",
      decimals: 18,
      isStable: true,
      isTempHidden: true,
      imageUrl: "https://assets.coingecko.com/coins/images/16786/small/mimlogopng.png",
    },
  ],
  [AVALANCHE_FUJI]: [
    {
      name: "Avalanche",
      symbol: "AVAX",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png?1604021818",
    },
    {
      name: "Wrapped AVAX",
      symbol: "WAVAX",
      decimals: 18,
      address: "0x1D308089a2D1Ced3f1Ce36B1FcaF815b07217be3",
      isWrapped: true,
      baseSymbol: "AVAX",
      imageUrl: "https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png?1604021818",
    },
    {
      name: "Ethereum (WETH.e)",
      symbol: "ETH",
      address: "0x8226EC2c1926c9162b6F815153d10018A7ccdf07",
      decimals: 18,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      address: "0xC492c8d82DC576Ad870707bb40EDb63E2026111E",
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
  ],
};

export const ADDITIONAL_TOKENS: { [chainId: number]: Token[] } = {
  [ARBITRUM]: [
    {
      name: "GMX",
      symbol: "GMX",
      address: getContract(ARBITRUM, "GMX"),
      decimals: 18,
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    {
      name: "Escrowed GMX",
      symbol: "esGMX",
      address: getContract(ARBITRUM, "ES_GMX"),
      decimals: 18,
    },
    {
      name: "GMX LP",
      symbol: "GLP",
      address: getContract(ARBITRUM, "GLP"),
      decimals: 18,
      imageUrl: "https://github.com/gmx-io/gmx-assets/blob/main/GMX-Assets/PNG/GLP_LOGO%20ONLY.png?raw=true",
    },
  ],
  [FANTOM]: [
    {
      name: "GMX",
      symbol: "GMX",
      address: getContract(ARBITRUM, "GMX"),
      decimals: 18,
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    {
      name: "Escrowed GMX",
      symbol: "esGMX",
      address: getContract(ARBITRUM, "ES_GMX"),
      decimals: 18,
    },
    {
      name: "GMX LP",
      symbol: "GLP",
      address: getContract(ARBITRUM, "GLP"),
      decimals: 18,
      imageUrl: "https://github.com/gmx-io/gmx-assets/blob/main/GMX-Assets/PNG/GLP_LOGO%20ONLY.png?raw=true",
    },
  ],
  [AVALANCHE]: [
    {
      name: "GMX",
      symbol: "GMX",
      address: getContract(AVALANCHE, "GMX"),
      decimals: 18,
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    {
      name: "Escrowed GMX",
      symbol: "esGMX",
      address: getContract(AVALANCHE, "ES_GMX"),
      decimals: 18,
    },
    {
      name: "GMX LP",
      symbol: "GLP",
      address: getContract(ARBITRUM, "GLP"),
      decimals: 18,
      imageUrl: "https://github.com/gmx-io/gmx-assets/blob/main/GMX-Assets/PNG/GLP_LOGO%20ONLY.png?raw=true",
    },
  ],
};

export const PLATFORM_TOKENS: { [chainId: number]: { [symbol: string]: Token } } = {
  [ARBITRUM]: {
    // arbitrum
    GMX: {
      name: "GMX",
      symbol: "GMX",
      decimals: 18,
      address: getContract(ARBITRUM, "GMX"),
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    GLP: {
      name: "GMX LP",
      symbol: "GLP",
      decimals: 18,
      address: getContract(ARBITRUM, "StakedGlpTracker"), // address of fsGLP token because user only holds fsGLP
      imageUrl: "https://github.com/gmx-io/gmx-assets/blob/main/GMX-Assets/PNG/GLP_LOGO%20ONLY.png?raw=true",
    },
  },
  [AVALANCHE]: {
    // avalanche
    GMX: {
      name: "GMX",
      symbol: "GMX",
      decimals: 18,
      address: getContract(AVALANCHE, "GMX"),
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    GLP: {
      name: "GMX LP",
      symbol: "GLP",
      decimals: 18,
      address: getContract(AVALANCHE, "StakedGlpTracker"), // address of fsGLP token because user only holds fsGLP
      imageUrl: "https://github.com/gmx-io/gmx-assets/blob/main/GMX-Assets/PNG/GLP_LOGO%20ONLY.png?raw=true",
    },
  },
};

export const ICONLINKS = {
  [ARBITRUM_TESTNET]: {
    GMX: {
      coingecko: "https://www.coingecko.com/en/coins/gmx",
      arbitrum: "https://arbiscan.io/address/0xfc5a1a6eb076a2c7ad06ed22c90d7e710e35ad0a",
    },
    GLP: {
      arbitrum: "https://testnet.arbiscan.io/token/0xb4f81Fa74e06b5f762A104e47276BA9b2929cb27",
    },
  },
  [ARBITRUM]: {
    GMX: {
      coingecko: "https://www.coingecko.com/en/coins/gmx",
      arbitrum: "https://arbiscan.io/address/0xfc5a1a6eb076a2c7ad06ed22c90d7e710e35ad0a",
    },
    GLP: {
      arbitrum: "https://arbiscan.io/token/0x1aDDD80E6039594eE970E5872D247bf0414C8903",
      reserves: "https://portfolio.nansen.ai/dashboard/gmx?chain=ARBITRUM",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/ethereum",
    },
    BTC: {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      arbitrum: "https://arbiscan.io/address/0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f",
    },
    LINK: {
      coingecko: "https://www.coingecko.com/en/coins/chainlink",
      arbitrum: "https://arbiscan.io/address/0xf97f4df75117a78c1a5a0dbb814af92458539fb4",
    },
    UNI: {
      coingecko: "https://www.coingecko.com/en/coins/uniswap",
      arbitrum: "https://arbiscan.io/address/0xfa7f8980b0f1e64a2062791cc3b0871572f1f7f0",
    },
    USDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      arbitrum: "https://arbiscan.io/address/0xff970a61a04b1ca14834a43f5de4533ebddb5cc8",
    },
    USDT: {
      coingecko: "https://www.coingecko.com/en/coins/tether",
      arbitrum: "https://arbiscan.io/address/0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9",
    },
    DAI: {
      coingecko: "https://www.coingecko.com/en/coins/dai",
      arbitrum: "https://arbiscan.io/address/0xda10009cbd5d07dd0cecc66161fc93d7c9000da1",
    },
    MIM: {
      coingecko: "https://www.coingecko.com/en/coins/magic-internet-money",
      arbitrum: "https://arbiscan.io/address/0xfea7a6a0b346362bf88a9e4a88416b77a57d6c2a",
    },
    FRAX: {
      coingecko: "https://www.coingecko.com/en/coins/frax",
      arbitrum: "https://arbiscan.io/address/0x17fc002b466eec40dae837fc4be5c67993ddbd6f",
    },
  },
  [AVALANCHE]: {
    GMX: {
      coingecko: "https://www.coingecko.com/en/coins/gmx",
      avalanche: "https://snowtrace.io/address/0x62edc0692bd897d2295872a9ffcac5425011c661",
    },
    GLP: {
      avalanche: "https://snowtrace.io/address/0x9e295B5B976a184B14aD8cd72413aD846C299660",
      reserves: "https://portfolio.nansen.ai/dashboard/gmx?chain=AVAX",
    },
    AVAX: {
      coingecko: "https://www.coingecko.com/en/coins/avalanche",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/weth",
      avalanche: "https://snowtrace.io/address/0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab",
    },
    WBTC: {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      avalanche: "https://snowtrace.io/address/0x50b7545627a5162f82a992c33b87adc75187b218",
    },
    BTC: {
      coingecko: "https://www.coingecko.com/en/coins/bitcoin-avalanche-bridged-btc-b",
      avalanche: "https://snowtrace.io/address/0x152b9d0FdC40C096757F570A51E494bd4b943E50",
    },
    MIM: {
      coingecko: "https://www.coingecko.com/en/coins/magic-internet-money",
      avalanche: "https://snowtrace.io/address/0x130966628846bfd36ff31a822705796e8cb8c18d",
    },
    USDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      avalanche: "https://snowtrace.io/address/0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e",
    },
    "USDC.e": {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin-avalanche-bridged-usdc-e",
      avalanche: "https://snowtrace.io/address/0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664",
    },
  },
  [AVALANCHE_FUJI]: {
    AVAX: {
      coingecko: "https://www.coingecko.com/en/coins/avalanche",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/weth",
      avalanche: "https://testnet.snowtrace.io/address/0x8226EC2c1926c9162b6F815153d10018A7ccdf07",
    },
    USDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      avalanche: "https://testnet.snowtrace.io/address/0xC492c8d82DC576Ad870707bb40EDb63E2026111E",
    },
  },
  [FANTOM]: {
    AVAX: {
      coingecko: "https://www.coingecko.com/en/coins/avalanche",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/weth",
      avalanche: "https://testnet.snowtrace.io/address/0x8226EC2c1926c9162b6F815153d10018A7ccdf07",
    },
    USDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      avalanche: "https://testnet.snowtrace.io/address/0xC492c8d82DC576Ad870707bb40EDb63E2026111E",
    },
  },
  [FANTOM_TESTNET]: {
    AVAX: {
      coingecko: "https://www.coingecko.com/en/coins/avalanche",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/weth",
      avalanche: "https://testnet.snowtrace.io/address/0x8226EC2c1926c9162b6F815153d10018A7ccdf07",
    },
    USDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      avalanche: "https://testnet.snowtrace.io/address/0xC492c8d82DC576Ad870707bb40EDb63E2026111E",
    },
  },
};

export const GLP_POOL_COLORS = {
  ETH: "#6062a6",
  BTC: "#F7931A",
  WBTC: "#F7931A",
  USDC: "#2775CA",
  "USDC.e": "#2A5ADA",
  USDT: "#67B18A",
  MIM: "#9695F8",
  FRAX: "#000",
  DAI: "#FAC044",
  UNI: "#E9167C",
  AVAX: "#E84142",
  LINK: "#3256D6",
};

export const TOKENS_MAP: { [chainId: number]: { [address: string]: Token } } = {};
export const TOKENS_BY_SYMBOL_MAP: { [chainId: number]: { [symbol: string]: Token } } = {};
export const WRAPPED_TOKENS_MAP: { [chainId: number]: Token } = {};
export const NATIVE_TOKENS_MAP: { [chainId: number]: Token } = {};
export const LP_TOKENS_MAP: { [chainId: number]: Token[] } = {};
export const POOL_ASSETS_MAP: { [chainId: number]: Token[] } = {};

const CHAIN_IDS = [
  BASE,
  BASE_GOERLI,
  MAINNET,
  TESTNET,
  FANTOM,
  FANTOM_TESTNET,
  ARBITRUM,
  ARBITRUM_TESTNET,
  AVALANCHE,
  AVALANCHE_FUJI,
];

for (let j = 0; j < CHAIN_IDS.length; j++) {
  const chainId = CHAIN_IDS[j];
  TOKENS_MAP[chainId] = {};
  TOKENS_BY_SYMBOL_MAP[chainId] = {};
  let tokens = TOKENS[chainId];
  if (ADDITIONAL_TOKENS[chainId]) {
    tokens = tokens.concat(ADDITIONAL_TOKENS[chainId]);
  }

  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i];
    TOKENS_MAP[chainId][token.address] = token;
    TOKENS_BY_SYMBOL_MAP[chainId][token.symbol] = token;
  }
}

for (const chainId of CHAIN_IDS) {
  for (const token of TOKENS[chainId]) {
    if (token.isWrapped) {
      WRAPPED_TOKENS_MAP[chainId] = token;
    } else if (token.isNative) {
      NATIVE_TOKENS_MAP[chainId] = token;
    }
    if (token.isLPToken) {
      if (LP_TOKENS_MAP[chainId]) {
        LP_TOKENS_MAP[chainId].push(token);
      } else {
        LP_TOKENS_MAP[chainId] = [token];
      }
    }
    if (token.isPoolAssets) {
      if (POOL_ASSETS_MAP[chainId]) {
        POOL_ASSETS_MAP[chainId].push(token);
      } else {
        POOL_ASSETS_MAP[chainId] = [token];
      }
    }
  }
}

export function getWrappedToken(chainId: number) {
  return WRAPPED_TOKENS_MAP[chainId];
}

export function getNativeToken(chainId: number) {
  return NATIVE_TOKENS_MAP[chainId];
}

export function getTokens(chainId: number) {
  return TOKENS[chainId];
}

export function getPoolAssets(chainId: number) {
  return POOL_ASSETS_MAP[chainId];
}
export function getLPToken(chainId: number) {
  return LP_TOKENS_MAP[chainId];
}

export function isValidToken(chainId: number, address: string) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  return address in TOKENS_MAP[chainId];
}

export function getToken(chainId: number, address: string) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  if (address === placeHolderAddress) address = ethers.constants.AddressZero;
  if (!TOKENS_MAP[chainId][address]) {
    // throw new Error(`Incorrect address "${address}" for chainId ${chainId}`);
  }
  return TOKENS_MAP[chainId][address];
}

export function getTokenBySymbol(chainId: number, symbol: string) {
  const token = TOKENS_BY_SYMBOL_MAP[chainId][symbol];
  if (!token) {
    throw new Error(`Incorrect symbol "${symbol}" for chainId ${chainId}`);
  }
  return token;
}

export function getTokenByBaseSymbol(chainId: number, symbol: string) {
  const token = TOKENS[chainId].find((token) => token.baseSymbol === symbol);
  if (!token) {
    throw new Error(`Incorrect symbol "${symbol}" for chainId ${chainId}`);
  }
  return token;
}

export function getTokenBySymbols(chainId: number, symbols: Array<string>) {
  if (!symbols || symbols.length === 0) return [];
  let tokens: Array<Token> = [];
  for (let symbol of symbols) {
    tokens.push(TOKENS_BY_SYMBOL_MAP[chainId][symbol]);
  }
  return tokens;
}

export function getWhitelistedTokens(chainId: number) {
  return TOKENS[chainId].filter((token) => token.symbol !== "USDG");
}

export function getVisibleTokens(chainId: number) {
  return getWhitelistedTokens(chainId).filter((token) => !token.isWrapped && !token.isTempHidden);
}

export function getNormalizedTokenSymbol(tokenSymbol) {
  if (["WBTC", "WETH", "WAVAX"].includes(tokenSymbol)) {
    return tokenSymbol.substr(1);
  } else if (tokenSymbol === "BTC.b") {
    return "BTC";
  }
  return tokenSymbol;
}

export const AVAILABLE_CHART_TOKENS = {
  [ARBITRUM]: ["ETH", "BTC", "LINK", "UNI"],
  [AVALANCHE]: ["AVAX", "ETH", "BTC"],
  [FANTOM]: ["FTM", "ETH", "BTC"],
  [FANTOM_TESTNET]: ["FTM", "ETH", "BTC"],
  [BASE]: ["ETH", "BTC"],
  [BASE_GOERLI]: ["ETH", "BTC"],
};

export function isChartAvailabeForToken(chainId: number, tokenSymbol: string) {
  const token = getTokenByBaseSymbol(chainId, tokenSymbol);
  if (!token) return false;
  return (token.isStable || AVAILABLE_CHART_TOKENS[chainId]?.includes(token.symbol)) ?? false;
}
