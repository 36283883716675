import "./Orders.css";
import { useEffect, useState } from "react";
import cx from "classnames";
import { useSwapHistory } from "domain/trading";
import ExternalLinkIcon from "img/ic_external_link.png";
import { getExplorerUrl } from "config/chains";
import LeftPageIcon from "img/ic_left_page.png";
import UnActiveLeftPageIcon from "img/ic_left_page_unactive.png";
import RightPageIcon from "img/ic_right_page.png";
import UnActiveRightPageIcon from "img/ic_right_page_unactive.png";
export const SpotHistory = ({ chainId, account }) => {
  const [page, setPage] = useState(1);
  const historyList = useSwapHistory(chainId, account, page - 1);
  const clickPage = (type) => {
    let newPage;
    if (type === 0) {
      newPage = page > 1 ? page - 1 : 1;
    } else {
      newPage = historyList?.length < 10 ? page : page + 1;
    }
    setPage(newPage);
  };
  const getText = (history) => {
    const { status, tokenIn, tokenOut, amountIn, amountOut } = history;
    if (status === "FILLED") {
      return ` ${amountIn} ${tokenIn.baseSymbol} -> ${amountOut} ${tokenOut.baseSymbol}`;
    }
  };
  const clickExplorer = (tx) => {
    window.open(`${getExplorerUrl(chainId)}/tx/${tx}`, "_blank");
  };
  return (
    <div className="scroll-x">
      <table className="table table-dark table-hover table-striped align-middle  " style={{ marginBottom: 0 }}>
        <thead className="Order-header">
          <tr>
            <th scope="col">Time</th>
            <th scope="col">Action</th>
            <th scope="col"></th>
          </tr>
        </thead>

        <tbody>
          {historyList &&
            historyList.map((history, index) => (
              <tr key={index}>
                <td>
                  <div className="Earn-detail-left-subtitle">{history.createTime}</div>
                </td>
                <td>{`${getText(history)}  `}</td>
                <td>
                  <img
                    src={ExternalLinkIcon}
                    alt=""
                    className="icon-xs"
                    onClick={() => {
                      clickExplorer(history.tx);
                    }}
                  ></img>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className="Liquidity-card-split" style={{ marginTop: 0 }}></div>

      <div className="flex justify-content-end mt-20 mr-20">
        <div className="flex aligns-center">
          <img
            alt=""
            src={page === 1 ? UnActiveLeftPageIcon : LeftPageIcon}
            style={{ height: "1.5rem", width: "auto", marginRight: "0.5rem" }}
            onClick={() => {
              clickPage(0);
            }}
            disabled={page === 1}
          ></img>
          Page {page}
          <img
            alt=""
            src={historyList?.length < 10 ? UnActiveRightPageIcon : RightPageIcon}
            style={{ height: "1.5rem", width: "auto", marginLeft: "0.5rem" }}
            onClick={() => {
              clickPage(1);
            }}
            disabled={historyList?.length < 10}
          ></img>
        </div>
      </div>
    </div>
  );
};
