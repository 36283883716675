import "./Activity.css";
import LaunchVideo from "video/launch.mp4";
import ButtonWithIcon from "components/Button/ButtonWithIcon";
import RightIcon from "img/ic_staking_jump.png";
import EventBottom from "./EventBottom";

function DGTActivity({ redirectPopupTimestamp, showRedirectModal }) {
  const learnMore = () => {
    window.open("https://blog.dip.exchange/introducing-dgt-fair-launch-63e0bc2d7dae", "__blank");
  };
  return (
    <div className="pb-30">
      <div className="Activity-bg">
        <div className="Activity-header">
          <div
            className=" default-container row justify-center aligns-center"
            style={{ marginRight: "auto", marginLeft: "auto" }}
          >
            <div className="col-12 col-md-7">
              <span className="Activity-title fw-bold">Introducing DGT Fair Launch</span>
              <div className="Activity-sub-title mt-20">The long wait is over. Let the rally begin.</div>
              <div className="mt-20">
                <span className="Activity-link" onClick={learnMore}>
                  learn more
                </span>
              </div>
              <div className="mt-20 flex aligns-center">
                <div className="flex-1 mr-5">
                  <a href="https://app.dip.exchange/#/dao">
                    <ButtonWithIcon variant="primary-action" text="Launch DGT" icon={RightIcon} />
                  </a>
                </div>
                <div className="flex-1 ml-5">
                  {/* <Link to="/faucet">
                    <Button variant="secondary-action" className="w-100">
                      Get $100
                    </Button>
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-5 mt-20 ">
              <img
                src="https://miro.medium.com/v2/resize:fit:1400/format:webp/1*WtOj1SKzXoxbwvngEUZkCw.png"
                alt="detail"
                width="100%"
                height="auto"
                className="Activity-img mt-20"
              ></img>
            </div>
          </div>
        </div>
      </div>
      <div className="default-container">
        <div className="Activity-content-group  ">
          <div className="Activity-content-title fs-2">Introducing DGT Fair Launch</div>
          {/* <div className="waring-color mt-20">Event Period: August 11th 2023 2pm UTC - September 24th 2023 2pm UTC</div> */}
          <div className="mt-20 ">
            The long wait is over. Let the rally begin.
            <div className="text-center">
              <img
                src="https://miro.medium.com/v2/resize:fit:1400/format:webp/1*WtOj1SKzXoxbwvngEUZkCw.png"
                width="80%"
                height="auto"
                className="Activity-img mt-20"
                alt=""
              ></img>
            </div>
            <div className="mt-20">
              DIP Exchange Governance Token ($DGT) Fair Launch will be conducted in November 1st 2023 2pm UTC with
              <a className="Activity-link-a ml-5 mr-5" href="https://bouncefinance.medium.com/">
                Bounce.finance
              </a>
              through Dutch Auction Process. There is no pre-mine and pre-allocation. All users and community members,
              including DIP team, will be required to earn or purchase at the fair launch date.
            </div>
          </div>

          <div className="fw-bold fs-2 mt-5">What is DIP Exchange Governance Token ($DGT)?</div>
          <div className="mt-20">
            $DGT is a governance token that governs the
            <a href="https://docs.dip.exchange/governance/dao-treasury" className="Activity-link-a-underline ml-5">
              DAO Treasury
            </a>
            . It is also used to pass governance proposals and list new trading pairs. $DGT will earn 10% of protocol
            revenue and be used to redeem a portion of the treasury depending on $DGT circulating supply.
          </div>
          <div className="mt-20">
            At the time of writing, the DAO Treasury balance is approx. $700,000 (incl. vesting tokens) and redeemable
            treasury balance is approx. $88,000. The treasury also earns 10% of protocol revenue and acts as an
            insurance fund for all participants of DIP’s ecosystem.
          </div>
          <div className="mt-20">
            Yes, this also means you can redeem up to $88,000 by burning your $DGT that you purchase. This will also
            mean that the minimum MC of $DGT will be $88,000.
          </div>
          <div className="mt-20">
            You will be able to check live balances of DAO Treasury nearing towards the fair launch date.
            <a href="https://app.dip.exchange/#/dao" className="Activity-link-a-underline ml-5">
              https://app.dip.exchange/#/dao
            </a>
          </div>
          <div className="fw-bold fs-2 mt-5">How to Purchase $DGT? What is Dutch Auction?</div>
          <div className="mt-20">
            A Dutch Auction, also known as descending price auction, refers to a type of auction in which an auctioneer
            starts with a very high price, incrementally lowering the price until someone places a bid. The first bid
            wins the auction, assuming the price is above the reserve price, avoiding any bidding wars.
          </div>
          <div className="mt-20">DIP Dutch Auction details are as follows:</div>
          <div className="mt-20 ml-20">
            <div className="flex aligns-center mt-20">
              <div className="Activity-mark"></div>
              <span className="ml-10 mr-5 fw-bold">Bid Asset:</span> $DIP
            </div>
            <div className="flex aligns-center mt-20">
              <div className="Activity-mark"></div>
              <span className="ml-10 mr-5 fw-bold">Starting Price:</span> Preset by DIP DAO in DIP/DGT
            </div>
            <div className="flex aligns-center mt-20">
              <div className="Activity-mark"></div>
              <span className="ml-10 mr-5 fw-bold">Floor Price:</span> Preset by DIP DAO in DIP/DGT
            </div>
            <div className="flex aligns-center mt-20">
              <div className="Activity-mark"></div>
              <span className="ml-10 mr-5 fw-bold">Clearing Price:</span> Decreases linearly throughout the auction
              period.
            </div>
            <div className="flex aligns-center mt-20">
              <div className="Activity-mark"></div>
              <span className="ml-10 mr-5 fw-bold">Auction Close:</span> Within 24 hours or when price reach the ceiling
              price.
            </div>
            <div className="flex aligns-center mt-20">
              <div className="Activity-mark"></div>
              <span className="ml-10 mr-5 fw-bold">Distribution:</span> Immediate after the closing at clearing price.
            </div>
            <div className="flex aligns-center mt-20">
              <div className="Activity-mark"></div>
              <span className="ml-10 mr-5 fw-bold">Burn:</span> If the auction ends successfully, DIP is immediately
              burned.
            </div>
            <div className="flex mt-20">
              <div className="Activity-mark mt-3"></div>
              <span className="ml-10 mr-5 fw-bold">Refund:</span> If the last bidder’s contribution exceed the ceiling
              price, it is fully refunded. If the last bidder enters an DIP amount larger than the amount sold, the
              unused DIP will be refunded.
            </div>
          </div>

          <div className="fw-bold fs-2 mt-5">How do I participate?</div>
          <div className="mt-10">To purchase or to earn $DGT, you will need $DIP.</div>
          <div className="mt-20">To purchase $DIP</div>
          <div className="mt-20 ml-20">
            <div className="flex aligns-center mt-20">
              <div className="Activity-mark"></div>
              <div className="ml-10">
                Visit Buy DIP —{" "}
                <a href="https://app.dip.exchange/#/buy" className="Activity-link-a-underline">
                  https://app.dip.exchange/#/buy
                </a>
              </div>
            </div>
            <div className="flex aligns-center mt-20">
              <div className="Activity-mark"></div>
              <div className="ml-10">
                Join Telegram Group for live updates —{" "}
                <a href="https://t.me/dipexchange_en" className="Activity-link-a-underline">
                  https://t.me/dipexchange_en
                </a>
              </div>
            </div>
          </div>
          <div className="mt-20">To earn $DGT</div>
          <div className="mt-20 ml-20">
            <div className="flex aligns-center mt-20">
              <div className="Activity-mark"></div>
              <div className="ml-10">
                Visit DIP DAO Staking Page —{" "}
                <a href=" https://app.dip.exchange/#/dao" className="Activity-link-a-underline">
                  https://app.dip.exchange/#/dao
                </a>
              </div>
            </div>
            <div className="flex aligns-center mt-20">
              <div className="Activity-mark"></div>
              <div className="ml-10">Stake $DIP</div>
            </div>
          </div>
          <div className="mt-20">To purchase $DGT</div>
          <div className="mt-20 ml-20">
            <div className="flex aligns-center mt-20">
              <div className="Activity-mark"></div>
              <div className="ml-10">
                Visit Auction Page —{" "}
                <a href=" https://app.bounce.finance/launchpad/dip_exchange" className="Activity-link-a-underline">
                  https://app.bounce.finance/launchpad/dip_exchange
                </a>
              </div>
            </div>
            <div className="flex aligns-center mt-20">
              <div className="Activity-mark"></div>
              <div className="ml-10">Participate and win at the auction</div>
            </div>
          </div>
          <EventBottom />
        </div>
      </div>
    </div>
  );
}

export default DGTActivity;
