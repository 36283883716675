import { FANTOM_TESTNET } from "./chains";
// import { PRODUCTION_PREVIEW_KEY } from "./localStorage";

export const UI_VERSION = "1.4";

export const IS_TOUCH = "ontouchstart" in window;

export function isDevelopment() {
  // let key;
  // try {
  //   key = localStorage.getItem(PRODUCTION_PREVIEW_KEY);
  // } catch (e) {
  //   key = false;
  // }
  // const isProductionPreview = key ? Boolean(key) : true;

  return (
    // window.location.host?.includes("testnet") && !window.location.host?.includes("ipfs.io")
    (isLocal() || window.location.host?.includes("testnet") || window.location.host?.includes("dev")) &&
    !window.location.host?.includes("ipfs.io")
  );
  //&& !isProductionPreview
}

export function isLocal() {
  return window.location.host?.includes("localhost");
}

export function isFantomTestnet(chainId: number) {
  return chainId === FANTOM_TESTNET;
}
