import { BASE, BASE_GOERLI } from "config/chains";
import { isDevelopment, isLocal } from "config/env";

//todo testnet only
// export const defaultApiUrl = isLocal() ? "http://localhost:30000" : "https://api.dip.exchange";
export const defaultApiUrl = "https://api.dip.exchange";

const bindUrl = `${defaultApiUrl}/discord/bind`;
export const aumUrl = `${defaultApiUrl}/aum`;
export const dcUserInfoUrl = `${defaultApiUrl}/dcUserInfo`;

export async function bindDiscord(address) {
  window.open(`${bindUrl}/${address}`, "__blank");
}

export function getApiPathName(chainId) {
  if (chainId === BASE) {
    return "base";
  } else if (chainId === BASE_GOERLI) {
    return "basegoerli";
  }
}
