import { BigNumberish, Signer, ethers } from "ethers";
import { formatAmount, bigNumberify, expandOfStringDecimals, formatMoney } from "./numbers";
import { Token } from "domain/tokens";
import { getProvider } from "./rpc";
import { getContract } from "config/contracts";
import Oracle from "abis/Oracle.json";
import { Web3Provider } from "@ethersproject/providers";
import Pool from "abis/Pool.json";
import { WRAPPED_TOKENS_MAP, getTokenBySymbol } from "config/tokens";
import BigNumber from "bignumber.js";

const weights = {
  BTC: 30800,
  ETH: 25000,
  USDT: 41000,
  FTM: 3000,
  WFTM: 3000,
};

const fees = {
  addRemoveLiquidityFee: 20000000,
  taxBasisPoint: 30000000,
  positionFee: 10000000,
  liquidationFee: "5000000000000000000000000000000",
  baseSwapFee: 30000000,
  stableCoinBaseSwapFee: 10000000,
  stableCoinTaxBasisPoint: 10000000,
  daoFee: 3500000000,
  minExecutionFee: 350000000000000,
  minSwapExecutionFee: 150000000000000,
};
export const defaultValueDecimals = 30;
const totalWeight = 100000;
export const defaultPriceDecimals = 12;
export const defaultDecimals = 10;
export const defaultTokenDecimals = 18;

export function getExecutionFee(): { executionFee: BigNumberish; swapExecutionFee: BigNumberish } {
  return {
    executionFee: parseFloat(formatAmount(fees.minExecutionFee, defaultTokenDecimals, 6)),
    swapExecutionFee: parseFloat(formatAmount(fees.minSwapExecutionFee, defaultTokenDecimals, 6)),
  };
}

export function getPositionFee(): BigNumberish {
  return fees.positionFee / Math.pow(10, defaultDecimals);
}

/**
 *
 * @param token
 * @param type 1: add or remove liquidity , 2: swap ,3:position
 * @returns
 */
export function getFee(token: Token, type: number): { baseFee: BigNumberish; taxBasisPoint: BigNumberish } {
  let baseFee: number = 0;
  switch (type) {
    case 1:
      baseFee = fees.addRemoveLiquidityFee;
      break;
    case 2:
      baseFee = token.isStable ? fees.stableCoinBaseSwapFee : fees.baseSwapFee;
      break;
  }
  return {
    baseFee: baseFee,
    taxBasisPoint: token.isStable ? fees.stableCoinTaxBasisPoint : fees.taxBasisPoint,
  };
}

export function getReceive(
  tokenPrice: number | string,
  lpPrice: number | string,
  amount: number | string,
  slippage: number | string,
  fee: number | string,
  isIncrease: boolean
) {
  //@ts-ignore
  if (!tokenPrice || !lpPrice || !amount) return;
  if (!fee) fee = 0;
  //bigNumberify(amount)?.mul(bigNumberify(tokenPrice)).div(lpPrice)
  //@ts-ignore
  tokenPrice = new BigNumber(tokenPrice);
  //@ts-ignore
  lpPrice = new BigNumber(lpPrice);
  //@ts-ignore
  amount = new BigNumber(amount);
  //@ts-ignore

  slippage = new BigNumber(slippage);
  //@ts-ignore

  fee = parseFloat(fee);
  //@ts-ignore
  const value = isIncrease ? amount.times(tokenPrice).div(lpPrice) : amount.times(lpPrice).div(tokenPrice);
  //@ts-ignore
  // console.log("amount", amount.toString());
  // console.log("tokenPrice", tokenPrice.toString());
  // console.log("lpPrice", lpPrice.toString());
  //@ts-ignore

  // console.log("sellvalue", value.toString());
  //@ts-ignore
  const valueAfterFee = value.times(1 - fee / 100);
  //@ts-ignore
  const minValue = valueAfterFee.times(1 - slippage / 100);

  return {
    //@ts-ignore
    value,
    //@ts-ignore
    valueAfterFee: formatMoney(valueAfterFee, isIncrease ? 4 : 10),
    //@ts-ignore
    minValue: formatMoney(minValue, isIncrease ? 4 : 6),
  };
}
export async function calcSwapFeeRate(
  chainId: number,
  signer: Signer | undefined,
  tokenInfo: { token: Token; minPrice: BigNumberish; maxPrice: BigNumberish; amount: BigNumberish }
) {
  const feeIn = await calcFeeRate(
    chainId,
    signer,
    tokenInfo.token,
    2,
    tokenInfo.amount,
    true,
    undefined,
    expandOfStringDecimals(tokenInfo.minPrice, defaultPriceDecimals + defaultTokenDecimals - tokenInfo.token.decimals)
  );
  const feeOut = await calcFeeRate(
    chainId,
    signer,
    tokenInfo.token,
    2,
    tokenInfo.amount,
    false,
    undefined,
    expandOfStringDecimals(tokenInfo.maxPrice, defaultPriceDecimals + defaultTokenDecimals - tokenInfo.token.decimals)
  );
  // console.log("feeIn");
  // console.log("feeOut");
  return feeIn > feeOut ? feeIn : feeOut;
}
export async function calcFeeRate(
  chainId: number,
  signer: Signer | undefined,
  token: Token,
  type: number,
  amount: BigNumberish,
  isIncrease: boolean,
  poolAmount?: BigNumberish,
  tokenPrice?: BigNumberish,
  virtualPoolValue?: BigNumberish
): Promise<BigNumberish> {
  if (token.isNative) {
    token = WRAPPED_TOKENS_MAP[chainId];
  }
  amount = expandOfStringDecimals(amount, token.decimals);
  if (!tokenPrice) {
    const oracleContract = new ethers.Contract(
      getContract(chainId, "Oracle"),
      Oracle.abi,
      getProvider(signer, chainId)
    );
    // console.log("-----", token.address);
    tokenPrice = await oracleContract.getPrice(token.address, !isIncrease);
    // console.log("tokenPrice", formatAmount(tokenPrice, defaultPriceDecimals));
  }
  let tokenWeight;
  if (!poolAmount || !virtualPoolValue || !tokenWeight) {
    const poolContract = new ethers.Contract(getContract(chainId, "Pool"), Pool.abi, getProvider(signer, chainId));
    if (!poolAmount) {
      const result = await poolContract.getPoolAsset(token.address);
      poolAmount = result.poolAmount;
      // console.log("poolAmount", formatAmount(poolAmount, token.decimals));
    }
    if (!virtualPoolValue) {
      virtualPoolValue = await poolContract.virtualPoolValue();
      // console.log("virtualPoolValue", formatAmount(virtualPoolValue, 30));
    }
    if (!tokenWeight) {
      tokenWeight = await poolContract.targetWeights(token.isNative ? token.wrappedAddress : token.address);
    }
  }
  //@ts-ignore
  const _targetValue = bigNumberify(virtualPoolValue).mul(tokenWeight).div(totalWeight);
  //@ts-ignore
  const _currentValue = bigNumberify(tokenPrice)?.mul(poolAmount);
  //@ts-ignore

  // console.log("_currentValue", bigNumberify(tokenPrice));
  // console.log("tokenPrice", tokenPrice);
  // console.log("poolAmount", poolAmount);
  //@ts-ignore
  const valueChange = bigNumberify(tokenPrice)?.mul(amount);
  //@ts-ignore
  const _nextValue = isIncrease ? _currentValue?.add(valueChange) : _currentValue?.sub(valueChange);
  //@ts-ignore
  const initDiff = _currentValue.sub(_targetValue).abs();
  //@ts-ignore
  const nextDiff = _nextValue.sub(_targetValue).abs();

  // console.log("_currentValue", formatAmount(_currentValue, 30));
  // console.log("_targetValue", formatAmount(_targetValue, 30));
  // console.log("_nextValue", formatAmount(_nextValue, 30));

  // console.log("initDiff", initDiff.toString());
  // console.log("nextDiff", nextDiff.toString());

  const { baseFee, taxBasisPoint } = getFee(token, type);
  // console.log(baseFee, taxBasisPoint);
  if (nextDiff.lt(initDiff)) {
    const feeAdjust = bigNumberify(taxBasisPoint)?.mul(initDiff).div(_targetValue);
    // console.log("feeAdjust sub", feeAdjust?.toString());
    //@ts-ignore
    const returnFee = bigNumberify(baseFee)?.gt(feeAdjust) ? bigNumberify(baseFee)?.sub(feeAdjust) : 0;
    //@ts-ignore
    return formatAmount(returnFee, defaultDecimals, 4) * 100;
  } else {
    const avgDiff = initDiff.add(nextDiff).div(2);
    const feeAdjust = avgDiff.gt(_targetValue)
      ? taxBasisPoint
      : bigNumberify(taxBasisPoint)?.mul(avgDiff).div(_targetValue);

    // console.log("feeAdjust", feeAdjust);
    //@ts-ignore
    return formatAmount(bigNumberify(baseFee)?.add(feeAdjust), defaultDecimals, 6) * 100;
  }
}
