import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, EffectFade, Pagination } from "swiper";

import ExternalLink from "components/ExternalLink/ExternalLink";
import "./HomeActivity.css";
import Button from "components/Button/Button";
import BetaTestImg from "img/beta-trade-contest.png";
import "swiper/swiper-bundle.min.css";
import { HeaderLink } from "components/Header/HeaderLink";
import "swiper/components/effect-fade/effect-fade.min.css";
import "swiper/components/pagination/pagination.min.css";
import { Link } from "react-router-dom";
import ButtonWithIcon from "components/Button/ButtonWithIcon";
import RightIcon from "img/ic_staking_jump.png";
import LaunchVideo from "video/launch.mp4";
SwiperCore.use([EffectFade, Autoplay, Pagination]);

export const HomeSwiper = ({ redirectPopupTimestamp, showRedirectModal }) => {
  const twitters = [
    // {
    //   content: "Introduce the 2nd #BTFD campaign on #Fantom with $30K for the first 3000 participants!",
    //   link: "https://twitter.com/DIP_Exchange/status/1677304390779281411",
    // },
    {
      content: "4 Days Left...30x Leverage #BTFD Campaign is coming! ",
      link: "https://twitter.com/DIP_Exchange/status/1677245932654723072",
    },
    {
      content: "Welcome, 1000+ members who've joined the #BTFD gang! ",
      link: "https://twitter.com/DIP_Exchange/status/1676597591566426115",
    },
    {
      content: "1st #BTFD Campaign - Day 2 Update! Nearly 3000 participants in Zealy contest.",
      link: "https://twitter.com/DIP_Exchange/status/1676555783377661952",
    },
    // {
    //   content: "Why @DIP_Exchange choose Fantom?",
    //   link: "https://twitter.com/DIP_Exchange/status/1676158396209459200",
    // },
    {
      content: "1st #BTFD Campaign - Day 1 Update! 1394+ participants in Zealy!",
      link: "https://twitter.com/DIP_Exchange/status/1676152790798995457",
    },
    // {
    //   content: "#Fantom fam, Here we come 😎",
    //   link: "https://twitter.com/DIP_Exchange/status/1675916317789159429",
    // },
  ];
  const LaunchExchangeButton = () => {
    return (
      <div className="row mt-20 ">
        <div className="col-md-4 col-12">
          <HeaderLink
            className="w-100"
            to="/markets"
            redirectPopupTimestamp={redirectPopupTimestamp}
            showRedirectModal={showRedirectModal}
          >
            <ButtonWithIcon variant="primary-action" text="Launch App" icon={RightIcon} />
          </HeaderLink>
        </div>
      </div>
    );
  };
  return (
    <div className="">
      <div className="Activity-container ">
        <Swiper
          spaceBetween={10}
          slidesPerView={1}
          // onSlideChange={() => console.log("slide change")}
          // onSwiper={(swiper) => console.log(swiper)}
          autoplay={{ delay: 10000 }}
          loop
          // navigation
          effect="fade"
          fadeEffect={{ crossFade: true }}
          pagination={{ clickable: true }}
        >
          <SwiperSlide>
            <div className="default-container row justify-center " style={{ marginLeft: "auto", marginRight: "auto" }}>
              <div className="col-12 col-md-7  mt-20  flex-col justify-between ">
                <div>
                  <span className="Activity-title fw-bold">Launch of DIP Exchange Web App</span>
                  <div className="Activity-sub-title mt-20 fw-bold">Experience something entirely new.</div>
                  <div className="mt-10 Activity-desc fs-3">
                    We are pleased to introduce a new feature for #BTFD fam, the DIP Exchange Web App.
                  </div>
                </div>

                <div className="row mt-20">
                  <div className="col-12 col-md-4">
                    <Link to="/events/webApp" className="w-40">
                      <ButtonWithIcon variant="primary-action" text="Details" icon={RightIcon} />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-5 mt-20 ">
                <img
                  src={require("img/bn_web_app.png")}
                  width="100%"
                  height="auto"
                  className="Activity-img"
                  alt=""
                ></img>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="default-container row justify-center " style={{ marginLeft: "auto", marginRight: "auto" }}>
              <div className="col-12 col-md-7  mt-20  flex-col justify-between ">
                <div>
                  <span className="Activity-title fw-bold">Introducing DGT Fair Launch</span>
                  <div className="Activity-sub-title mt-20 fw-bold">The long wait is over. Let the rally begin.</div>
                  <div className="mt-10 Activity-desc fs-3">
                    DIP Exchange Governance Token ($DGT) Fair Launch will be conducted in November 1st 2023 2pm UTC with
                    Bounce.finance through Dutch Auction Process.
                  </div>
                </div>

                <div className="row mt-20">
                  <div className="col-12 col-md-4">
                    <Link to="/events/dgt" className="w-40">
                      <ButtonWithIcon variant="primary-action" text="Details" icon={RightIcon} />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-5 mt-20 ">
                <img
                  src="https://miro.medium.com/v2/resize:fit:1400/format:webp/1*WtOj1SKzXoxbwvngEUZkCw.png"
                  width="100%"
                  height="auto"
                  className="Activity-img"
                  alt=""
                ></img>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="default-container row justify-center" style={{ marginLeft: "auto", marginRight: "auto" }}>
              <div className="col-12 col-md-7 mt-20 flex-col justify-between">
                <span className="Activity-title fw-bold">Time to wake up, degens! It's Onchain Summer</span>
                <div className="Activity-sub-title mt-20 fw-bold">Going live on August 15th 2023</div>
                {/* <div className="mt-10 Activity-desc">In the toughest times, DIP Exchange goes live on mainnet</div> */}
                <div className="row mt-20">
                  <div className="col-12 col-md-4">
                    <Link to="/events/lbp" className="w-40">
                      <ButtonWithIcon variant="primary-action" text="Details" icon={RightIcon} />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-5 mt-20 text-center">
                <div className="Home-video-container">
                  <video id="launchVideo" width="100%" height="auto" controls autoPlay loop muted>
                    <source src={LaunchVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div
              className="default-container row justify-center aligns-center"
              style={{ marginLeft: "auto", marginRight: "auto" }}
            >
              <div className="col-12 col-md-7 mt-20 ">
                <span className="Activity-title fw-bold">Better Decentralized Perpetual Exchange</span>
                <div className="Home-description mt-20 fw-bold" style={{ textAlign: "left" }}>
                  Trade BTC, ETH and other top
                  <br />
                  cryptocurrencies with up to 50x leverage directly from your wallet
                </div>
                <LaunchExchangeButton />
              </div>
              <div className="col-12 col-md-5 mt-20 text-center">
                <img
                  // src={require("img/Home-desc-left.png")}
                  src="https://miro.medium.com/v2/resize:fit:720/format:webp/1*kE9G4dW0srvE-R0E700ujQ.png"
                  width="100%"
                  height="auto"
                  className="Activity-img"
                  // style={{ maxHeight: "300px" }}
                  alt=""
                ></img>
              </div>
            </div>
          </SwiperSlide>

          {/* <SwiperSlide>
            <img
              src={require("img/twitter-banner-960x540.png")}
              style={{ width: "100%", height: "auto", maxHeight: "400px" }}
            ></img>
          </SwiperSlide> */}
        </Swiper>
        {/* <div className="default-container  mt-20">
          <div className="Home-twitters ">
            <div className=" Home-twitter-content flex">
              <img src={require("img/ic_announcement.png")} alt="" className="mr-10 icon-xs"></img>
              <div>
                <Swiper
                  direction={"vertical"}
                  autoplay={{ delay: 4000 }}
                  loop
                  //  effect="fade"
                  // fadeEffect={{ crossFade: true }}
                >
                  {twitters.map((data, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        window.open(data.link, "__blank");
                      }}
                    >
                      <SwiperSlide>{data.content}</SwiperSlide>
                    </div>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};
