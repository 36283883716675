import React from "react";
import { HeaderLink } from "components/Header/HeaderLink";
import LiquidityCard from "components/LiquidityCard/LiquidityCard";
import { useDaoStakingInfo, useDgtStakingInfo, useDipStakingInfo } from "domain/pool";
import { useChainId } from "lib/chains";
import { formatMoney } from "lib/numbers";
import useWallet from "lib/wallets/useWallet";

function Stakings ({ isHome, showRedirectModal, redirectPopupTimestamp }) {
  const { chainId } = useChainId();
  const { signer } = useWallet();

  const dipStakingInfo = useDipStakingInfo(chainId, signer);
  const daoStakingInfo = useDaoStakingInfo(chainId, signer);
  const dgtStakingInfo = useDgtStakingInfo(chainId, signer);
  const stakings = [
    {
      title: "DAO Staking",
      apr: daoStakingInfo?.apr ? formatMoney(daoStakingInfo?.apr, 2) : "---",
      management: daoStakingInfo?.totalDepositValue ? formatMoney(daoStakingInfo?.totalDepositValue, 2) : "---",
      type: 2,
      link: "/dao"
    },
    {
      title: "DIP Staking",
      apr: dipStakingInfo?.apr ? formatMoney(dipStakingInfo?.apr, 2) : "---",
      management: dipStakingInfo?.totalDepositValue ? formatMoney(dipStakingInfo?.totalDepositValue, 2) : "---",
      type: 2,
      icons: [require("img/DIP-token.png")],
      link: "/staking",
    },
    {
      title: "DGT Staking",
      apr: dgtStakingInfo?.apr ? formatMoney(dgtStakingInfo?.apr, 2) : "---",
      management: dgtStakingInfo?.totalDepositValue ? formatMoney(dgtStakingInfo?.totalDepositValue, 2) : "---",
      type: 2,
      icons: [require("img/dgt-white.png")],
      link: "/staking",
    },
  ];
  return (
    <div>
      <div className={isHome ? "Home-section-title" : "Page-title"}>Staking</div>
      <div className="row">
        {stakings.map((item, index) => (
          <div className="mt-20 col-12 col-md-6 col-lg-4 " key={index}>
            <HeaderLink
              isHomeLink={false}
              to={item.link}
              showRedirectModal={showRedirectModal}
              redirectPopupTimestamp={redirectPopupTimestamp}
            >
              <LiquidityCard item={item} />
            </HeaderLink>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Stakings;
