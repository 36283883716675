import { formatAmount, formatMoney } from "lib/numbers";
import {
  calcSwapFeeRate,
  getExecutionFee,
  getPositionFee,
  defaultValueDecimals,
  defaultPriceDecimals,
  defaultTokenDecimals,
  defaultDecimals,
} from "lib/pool";
import { BigNumber, ethers } from "ethers";
import { TOKENS, getToken, getTokenBySymbol, getTokenBySymbols } from "config/tokens";
import { getConstant } from "config/chains";
import { isDevelopment } from "config/env";

const dipInitPrice = 0.005;
const poolPerSecondReward = 2.77777;
const maxBorrowFee = 1000000;
export const MIN_COLLATERAL_VALUE = 10;
export const LIQ_FEE = 5;
export const MAX_LEVERAGE = 50;
export const LIMIT_MARGIN_PERCENT = 0.99;
const executionFees = getExecutionFee();
const positionFees = getPositionFee();
const { AddressZero } = ethers.constants;
// const abiCode = ethers.AbiCoder.defaultAbiCoder();

export function getPositionsInfoByPayToken (
  tokenPrice,
  orderType,
  payToken,
  positionsToken,
  Leverage,
  isLong,
  slippage
) {
  Leverage = parseFloat(Leverage);
  const payTokenPrice =
    tokenPrice && tokenPrice[payToken.token.baseSymbol] ? tokenPrice[payToken.token.baseSymbol].price : payToken.price;
  // console.log("order.type:", orderType);
  const entryPrice =
    orderType.type === 1
      ? orderType.price
      : tokenPrice && tokenPrice[positionsToken.token.baseSymbol]
        ? tokenPrice[positionsToken.token.baseSymbol].price
        : positionsToken.price;
  // console.log("order.type:entryPrice:", entryPrice);
  // console.log("order.type:payPrice:", payTokenPrice);
  const price = payTokenPrice / entryPrice;
  const size = price * Leverage * payToken.amount;
  const liqPercent = ((payToken.amount * payTokenPrice - LIQ_FEE) * LIMIT_MARGIN_PERCENT) / (size * entryPrice);
  // console.log("liqPercent", liqPercent);
  const liqPrice = (isLong ? 1 - liqPercent : 1 + liqPercent) * entryPrice;
  const positionValue = size * entryPrice;
  const collateralValue = positionValue / Leverage;
  const positionFee = positionValue * positionFees;
  // console.log("positionFee", positionValue * positionFees);
  // console.log("positionFees", positionFees);
  const triggerPrice = orderType.type === 1 ? entryPrice : (isLong ? 1 + slippage : 1 - slippage) * entryPrice;

  return {
    size,
    liqPrice,
    entryPrice,
    collateralValue,
    positionValue,
    positionFee,
    triggerPrice,
  };
}

export function getPositionsInfoAndPosition (
  tokenPrice,
  orderType,
  payToken,
  positionsToken,
  assetToken,
  Leverage,
  isLong,
  slippage,
  positions
) {
  const newPositionInfo = getPositionsInfoByPayToken(
    tokenPrice,
    orderType,
    payToken,
    positionsToken,
    Leverage,
    isLong,
    slippage
  );
  let changed;
  if (positions) {
    for (let position of positions) {
      if (
        position.indexToken.baseSymbol === positionsToken.token.baseSymbol &&
        position.assetToken.baseSymbol === assetToken.baseSymbol
      ) {
        const oldCollateralValue = position.collateralValue;
        const oldNetValue = position.netValue;
        const oldSize = position.size;
        const oldEntryPrice = position.entryPrice;
        const newCollateralValue = newPositionInfo.collateralValue + oldCollateralValue;
        const newNetValue = newPositionInfo.collateralValue + oldNetValue;

        const newSize = position.size + newPositionInfo.positionValue;
        let newLeverage = newSize / newCollateralValue;
        // if (newLeverage > MAX_LEVERAGE) newLeverage = MAX_LEVERAGE;
        const newEntryPrice =
          newSize / ((isLong ? position.reserveAmount : position.reserveAmount / oldEntryPrice) + newPositionInfo.size);
        // console.log("position.newCollateralValue", newCollateralValue);
        // console.log("position.reserveAmount", position.reserveAmount);
        // console.log("position.newsize", newSize);
        // console.log("position.addsize", newPositionInfo.size);
        // console.log("position.oldsize", oldSize);
        // console.log("position.positionValue", newPositionInfo.positionValue);

        const liqPercent = ((newCollateralValue - LIQ_FEE) * LIMIT_MARGIN_PERCENT) / newSize;
        const newLiqPrice = (isLong ? 1 - liqPercent : 1 + liqPercent) * newEntryPrice;
        changed = {
          old: {
            collateralValue: oldCollateralValue,
            liqPrice: position.liqPrice,
            entryPrice: oldEntryPrice,
            leverage: position.leverage,
            netValue: oldNetValue,
          },
          new: {
            collateralValue: newCollateralValue,
            liqPrice: newLiqPrice,
            entryPrice: newEntryPrice,
            leverage: newLeverage,
            netValue: newNetValue,
          },
        };
      }
    }
  }
  return {
    ...newPositionInfo,
    changed,
  };
}

export function getPositionsInfoDePosition (tokenPrice, indexToken, isLong, amount, slippage, position, orderType) {
  let changed;
  const indexPrice = tokenPrice[indexToken.baseSymbol].price;
  const oldCollateralValue = position.collateralValue;
  const oldNetValue = position.netValue;
  const oldSize = position.size;
  const oldEntryPrice = position.entryPrice;
  const newCollateralValue = amount > oldCollateralValue ? 0 : oldCollateralValue - amount;
  const newNetValue = amount > oldNetValue ? 0 : oldNetValue - amount;
  let newLeverage = oldSize / newCollateralValue;
  // if (newLeverage > MAX_LEVERAGE) newLeverage = MAX_LEVERAGE;
  const changeAmount = isLong ? amount / indexPrice : amount;
  const newAmount = isLong ? position.reserveAmount - changeAmount : position.reserveAmount - changeAmount / indexPrice;
  const newEntryPrice = oldSize / newAmount;
  // console.log("position.newCollateralValue", newCollateralValue);
  // console.log("position.reserveAmount", position.reserveAmount);

  const liqPercent = ((newCollateralValue - LIQ_FEE) * LIMIT_MARGIN_PERCENT) / oldSize;
  const newLiqPrice = (isLong ? 1 - liqPercent : 1 + liqPercent) * newEntryPrice;
  // const triggerPrice = (isLong ? 1 + slippage : 1 - slippage) * indexPrice;
  // console.log("orderType", orderType);
  const triggerPrice = (isLong ? 1 - slippage : 1 + slippage) * (orderType.type === 0 ? indexPrice : orderType.price);

  changed = {
    old: {
      collateralValue: oldCollateralValue,
      liqPrice: position.liqPrice,
      entryPrice: oldEntryPrice,
      leverage: position.leverage,
      netValue: oldNetValue,
    },
    new: {
      collateralValue: newCollateralValue,
      liqPrice: newLiqPrice,
      entryPrice: newEntryPrice,
      leverage: newLeverage,
      netValue: newNetValue,
    },
  };

  return {
    triggerPrice,
    changeAmount,
    changed,
  };
}

export function getPositionsInfoDePositionClose (
  tokenPrice,
  receiveToken,
  indexToken,
  isLong,
  amount,
  slippage,
  position,
  orderType
) {
  const changeAmount = amount / position.leverage;
  let returnValue = getPositionsInfoDePosition(
    tokenPrice,
    indexToken,
    isLong,
    changeAmount,
    slippage,
    position,
    orderType
  );
  const oldSize = position.size;
  const newSize = amount > oldSize ? 0 : oldSize - amount;
  returnValue.changed.new.size = newSize;
  returnValue.changed.old.size = oldSize;
  returnValue.changedToken = ((amount / oldSize) * position.netValue) / tokenPrice[receiveToken.baseSymbol].price;
  returnValue.pnl = (position.netValue - position.collateralValue) * (amount / position.size);
  // console.log("pnl:", position.netValue - position.collateralValue);
  // console.log("pnl:", amount / position.size);
  return returnValue;
}

export async function getSwapAndExecutionFee (chainId, signer, payToken, assetToken) {
  let swapFee;
  // console.log("executionFees", executionFees);
  let executionFee = executionFees.executionFee;
  if (payToken.token.symbol !== assetToken.symbol) {
    swapFee = await calcSwapFeeRate(chainId, signer, payToken);
    executionFee += executionFees.swapExecutionFee;
  }
  // console.debug("SwapFee", swapFee);
  return {
    swapFee,
    executionFee,
  };
}

export function getMarketInfo (poolAsset, token) {
  if (!poolAsset) return { borrowFee: "", available: "" };
  const balance = poolAsset[0];
  const reverseAmount = poolAsset[1];
  return {
    borrowFee: formatAmount(
      reverseAmount.mul(BigNumber.from(maxBorrowFee)).div(balance.toString() === "0" ? 1 : balance),
      defaultDecimals,
      6
    ),
    available: parseFloat(formatAmount(balance.toString() === "0" ? 0 : balance.sub(reverseAmount), token.decimals, 6)),
  };
}

/**
 *
 *
 * @returns
 */
export function encodePositionData (data) {
  const encodedData = ethers.utils.defaultAbiCoder.encode(
    ["uint256", "address", "uint256", "uint256", "uint256", "bytes"],
    data
  );
  // console.log("Encoded Data:", encodedData);

  return encodedData;
}

export function encodeSwapData (data) {
  const encodedData = ethers.utils.arrayify(data);
  // console.log("Encoded Data:", encodedData.toString());

  return encodedData;
}

export function encodeDePositionData (data, isMarket) {
  //market : (uint256, address, uint256, uint256, bytes)
  //limit:  uint256, bool, address, uint256, uint256, bytes;

  const encodedData = ethers.utils.defaultAbiCoder.encode(
    isMarket
      ? ["uint256", "address", "uint256", "uint256", "bytes"]
      : ["uint256", "bool", "address", "uint256", "uint256", "bytes"],
    data
  );
  // console.log("Encoded Data:", encodedData);

  return encodedData;
}

/**
 *  orders type: 
      IPool pool;
      address owner;
      address indexToken;
      address collateralToken;
      address payToken;
      uint256 expiresAt;
      uint256 submissionBlock;
      uint256 price;
      uint256 executionFee;
      bool triggerAboveThreshold;

    requests type:
       Side side;
       uint256 sizeChange;
       uint256 collateral;
       UpdatePositionType updateType;
 */

export function getOrdersInfo (chainId, data) {
  if (!data) return;
  const orderIds = data.orderIds;
  const orders = data.takeOrders;
  let total = parseFloat(data.total.toString());
  let processOrders = [];
  const requests = data.takeRequests;

  for (let i = 0; i < orders.length; i++) {
    let order = orders[i];
    let request = requests[i];
    if (order.owner.toString() === AddressZero) {
      total--;
      continue;
    }
    const payToken = getToken(chainId, order.payToken.toString());
    const indexToken = getToken(chainId, order.indexToken.toString());
    const collateralToken = getToken(chainId, order.collateralToken.toString());
    const side = parseFloat(request.side.toString());
    const expiresAt = order.expiresAt.toString();
    // console.log("order.price:", order.price.toString());
    const updateType = parseFloat(request.updateType.toString());
    processOrders.push({
      id: parseFloat(orderIds[i].toString()),
      side,
      isLong: side === 0,
      type: expiresAt === "0" ? 1 : 0,
      updateType,
      indexToken,
      collateralToken,
      payToken,
      sizeChange: formatAmount(request.sizeChange.toString(), defaultValueDecimals, 2, true),
      collateralAmount: formatAmount(
        request.collateral.toString(),
        updateType === 1 ? defaultValueDecimals : payToken.decimals
      ),
      expiresAt: expiresAt,
      submissionBlock: order.submissionBlock.toString(),
      price: formatAmount(
        order.price.toString(),
        defaultPriceDecimals + defaultTokenDecimals - indexToken.decimals,
        indexToken.displayDecimals,
        true
      ),
      executionFee: order.executionFee.div(BigNumber.from(10).pow(defaultTokenDecimals)),
      triggerAboveThreshold: order.triggerAboveThreshold.toString() === "true",
    });
  }

  // console.log(processOrders);

  return { orders: processOrders.reverse(), total: total };
}

function processPrice (token, maxPrice, minPrice) {
  const displayDecimals = token.isNative ? 4 : 2;
  let max = maxPrice
    ? parseFloat(formatAmount(maxPrice, defaultPriceDecimals + defaultTokenDecimals - token.decimals))
    : undefined;
  let min = minPrice
    ? parseFloat(formatAmount(minPrice, defaultPriceDecimals + defaultTokenDecimals - token.decimals))
    : undefined;

  let price;
  if (maxPrice && minPrice) {
    price = ((max + min) / 2).toFixed(displayDecimals);
  } else if (maxPrice) {
    price = maxPrice;
  } else if (minPrice) {
    price = minPrice;
  }
  return {
    max,
    min,
    price,
  };
}

export function getTokenPrice (tokens, maxPrices, minPrices) {
  let tokenPrice = {};
  // console.log(tokens, maxPrices, minPrices);
  if (!tokens || !maxPrices || !minPrices) return {};
  for (let i = 0; i < tokens.length; i++) {
    tokenPrice[tokens[i].baseSymbol] = processPrice(tokens[i], maxPrices[i], minPrices[i]);
  }
  return tokenPrice;
}

/**
 * _getPositionKey(_owner, _indexToken, _collateralToken, _side);
 */
export function getPositionKey (account, indexAddress, assetAddress, side) {
  if (!account) account = AddressZero;
  const encodedData = ethers.utils.defaultAbiCoder.encode(
    ["address", "address", "address", "uint256"],
    [account, indexAddress, assetAddress, side]
  );
  const hash = ethers.utils.keccak256(encodedData);
  return hash;
}

/**
 * _getPositionKey(_owner, _indexToken, _collateralToken, _side);
 */
export function getAllPositionKey (chainId, account) {
  let keys = [];
  const allTokens = TOKENS[chainId];
  const defaultCollateralSymbol = getConstant(chainId, "defaultCollateralSymbol");
  const USDT = allTokens.find((token) => token.symbol === defaultCollateralSymbol);
  for (let token of allTokens) {
    if (token.symbol === "BTC" || token.symbol === "WETH") {
      keys.push(getPositionKey(account, token.address, token.address, 0)); //Long side
      keys.push(getPositionKey(account, token.address, USDT.address, 1)); //Short side
    }
  }
  // console.log("PositionKeys:", keys);
  return keys;
}

export function getPositionsInfo (chainId, data, tokenPrice) {
  if (!data) return;
  let positions = [];
  for (let i = 0; i < data.length; i++) {
    const { indexToken, assetToken } = getPositionsToken(chainId, i);
    // console.log(indexToken, assetToken);

    let position = data[i];

    const size = parseFloat(formatAmount(position.size, defaultValueDecimals, 2));
    if (size === 0) continue;
    const collateralValue = parseFloat(formatAmount(position.collateralValue, defaultValueDecimals, 2));
    const reserveAmount = parseFloat(formatAmount(position.reserveAmount, assetToken.decimals));
    const entryPrice = parseFloat(
      formatAmount(
        position.entryPrice,
        defaultPriceDecimals + defaultTokenDecimals - indexToken.decimals,
        indexToken.displayDecimals
      )
    );
    const isLong = indexToken.symbol === assetToken.symbol;

    const currentPrice = tokenPrice[indexToken.baseSymbol]?.price;
    const liqPercent = ((collateralValue - LIQ_FEE) * LIMIT_MARGIN_PERCENT) / size;
    // console.log("liqPercent", liqPercent, collateralValue, size);
    const liqPrice = (isLong ? 1 - liqPercent : 1 + liqPercent) * entryPrice;
    // console.log("liqPrice", liqPrice);

    let netValuePercent = (((currentPrice - entryPrice) / entryPrice) * size) / collateralValue;
    netValuePercent = isLong ? netValuePercent : 0 - netValuePercent;
    const netValue = collateralValue * (1 + netValuePercent);
    const borrowIndex = parseFloat(position.borrowIndex.toString());
    let leverage = size / collateralValue;
    // if (leverage > MAX_LEVERAGE) leverage = MAX_LEVERAGE;
    positions.push({
      size,
      originSize: position.size,
      side: isLong ? 0 : 1,
      netValue,
      leverage,
      netValuePercent,
      collateralValue,
      reserveAmount,
      entryPrice,
      borrowIndex,
      indexToken,
      assetToken,
      liqPrice,
      isLong,
    });
  }
  // console.log("positions", positions);
  return positions;
}

function getPositionsToken (chainId, index) {
  const allTokens = TOKENS[chainId];
  const defaultCollateralSymbol = getConstant(chainId, "defaultCollateralSymbol");
  const USDT = allTokens.find((token) => token.symbol === defaultCollateralSymbol);
  const BTC = allTokens.find((token) => token.symbol === "BTC");
  const ETH = allTokens.find((token) => token.symbol === "WETH");
  // const FTM = allTokens.find((token) => token.symbol === "WFTM");

  let indexToken, assetToken;
  switch (index) {
    case 0:
      indexToken = ETH;
      assetToken = ETH;
      break;
    case 1:
      indexToken = ETH;
      assetToken = USDT;
      break;
    case 2:
      indexToken = BTC;
      assetToken = BTC;
      break;
    case 3:
      indexToken = BTC;
      assetToken = USDT;
      break;

    default:
  }
  return { indexToken, assetToken };
}
