const EventBottom = () => {
  return (
    <div>
      <div className="text-center fw-bolder fs-1 mt-20">. . .</div>
      <div className="mt-20">
        <a href="https://dip.exchange/#/" className="Activity-link-a-underline mr-5">
          DIP Exchange
        </a>
        is a transparent decentralized derivatives exchange enabling the permissionless creation of perpetual trading
        for any asset by anyone.
      </div>
      <div className="mt-20">
        Start trading on
        <a href="https://dip.exchange/#/" className="Activity-link-a-underline ml-5 mr-5">
          DIP Exchange
        </a>
        today and take control of your decentralized trading journey.
      </div>

      <div className="fw-bold fs-2 mt-5">Official Links</div>
      <div className="mt-20 ml-20">
        <div className="flex aligns-center mt-20">
          <div className="Activity-mark"></div>
          <div className="ml-10">
            <a className="Activity-link-a-underline" href="https://www.dip.exchange">
              Website
            </a>
          </div>
        </div>
        <div className="flex aligns-center mt-20">
          <div className="Activity-mark"></div>
          <div className="ml-10">
            <a className="Activity-link-a-underline" href="https://docs.dip.exchange/getting-started/about-dip">
              Docs
            </a>
          </div>
        </div>
        <div className="flex aligns-center mt-20">
          <div className="Activity-mark"></div>
          <div className="ml-10">
            <a className="Activity-link-a-underline" href="https://twitter.com/DIP_Exchange">
              Twitter
            </a>
          </div>
        </div>
        <div className="flex aligns-center mt-20">
          <div className="Activity-mark"></div>
          <div className="ml-10">
            <a className="Activity-link-a-underline" href="https://t.me/dipexchange_en">
              Telegram
            </a>
          </div>
        </div>
        <div className="flex aligns-center mt-20">
          <div className="Activity-mark"></div>
          <div className="ml-10">
            <a className="Activity-link-a-underline" href="https://discord.com/invite/dip-exchange">
              Discord
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventBottom;
