import React from "react";
import "./LiquidityCard.css";
import RiskTip from "./RiskTip";
import cx from "classnames";

export default function LiquidityCard ({ item }) {
  const { icons, title, type, apr, management, risk } = item;
  const cellsSubTitle = type === 1 ? "Assets Under Management" : "Total Deposited";
  const cellClass = "flex justify-between aligns-center mt-20";
  return (
    <div className="Liquidity-card">
      {risk && <RiskTip type={risk}></RiskTip>}
      <div className="flex justify-between aligns-center">
        <div className="flex">
          {icons
            ? icons.map((icon, index) => (
              <img
                key={index}
                alt=""
                src={icon}
                className={cx("icon-sm", {
                  " mr-base": index === icons.length - 1,
                  "DefaultCard--ml10": index > 0,
                })}
              ></img>
            ))
            : ""}
          <div className="Liquidity-card-title">{title}</div>
        </div>
        <div>
          <img src={require("img/ic_staking_jump.png")} alt=""></img>
        </div>
      </div>
      {/* <div className=" Liquidity-card-split"></div> */}
      <div className={cellClass}>
        <div className="Liquidity-card-subtitle">APR</div>
        <div className="Liquidity-card-value">{apr}%</div>
      </div>
      <div className={cellClass}>
        <div className="Liquidity-card-subtitle">{cellsSubTitle}</div>
        <div className="Liquidity-card-value">${management}</div>
      </div>
    </div>
  );
}
