import "./Activity.css";
import LaunchVideo from "video/launch.mp4";
import ButtonWithIcon from "components/Button/ButtonWithIcon";
import RightIcon from "img/ic_staking_jump.png";
import EventBottom from "./EventBottom";

function LbpActivity({ redirectPopupTimestamp, showRedirectModal }) {
  const learnMore = () => {
    window.open("https://blog.dip.exchange/dip-is-moving-onchain-summer-btfd-64fe9b357435", "__blank");
  };
  return (
    <div className="pb-30">
      <div className="Activity-bg">
        <div className="Activity-header">
          <div
            className=" default-container row justify-center aligns-center"
            style={{ marginRight: "auto", marginLeft: "auto" }}
          >
            <div className="col-12 col-md-7">
              <span className="Activity-title fw-bold">DIP is Moving: Onchain Summer (🟡, #BTFD)</span>
              <div className="Activity-sub-title mt-20">The votes have been made. Base wins.</div>
              <div className="mt-20">
                <span className="Activity-link" onClick={learnMore}>
                  learn more
                </span>
              </div>
              <div className="mt-20 flex aligns-center">
                <div className="flex-1 mr-5">
                  <a href="https://app.dip.exchange/#/liquiditybootstrapping">
                    <ButtonWithIcon variant="primary-action" text=" Liquidity Bootstrapping" icon={RightIcon} />
                  </a>
                </div>
                <div className="flex-1 ml-5">
                  {/* <Link to="/faucet">
                    <Button variant="secondary-action" className="w-100">
                      Get $100
                    </Button>
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-5 mt-20 ">
              <video id="launchVideo" width="100%" height="auto" controls autoPlay loop muted>
                <source src={LaunchVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
      <div className="default-container">
        <div className="Activity-content-group  ">
          <div className="Activity-content-title fs-2">DIP is Moving: Onchain Summer (🟡, #BTFD)</div>
          {/* <div className="waring-color mt-20">Event Period: August 11th 2023 2pm UTC - September 24th 2023 2pm UTC</div> */}
          <div className="mt-20 ">
            As of August 8th 2023, the community voted, and they voted for Base.
            <div className="text-center">
              <img
                src="https://miro.medium.com/v2/resize:fit:640/format:webp/1*u5QKftngAySLn3VyATSgWg.png"
                width="80%"
                height="auto"
                className="Activity-img mt-20"
                alt=""
              ></img>
            </div>
            <div className="mt-20">
              As a result of the community vote, DIP Exchange will be migrating to Base and preparing for its fair
              launch of $DIP through a Liquidity Bootstrapping Event.
            </div>
          </div>
          <div className="mt-20 flex">
            <div className="Activity-tip-left"></div>
            <div className="Activity-tip-title ml-20">
              The DIP team will be launching on Base on August 15th 2023, at 2:00 PM UTC.
            </div>
          </div>
          <div className="mt-20">See below for detailed guide on the migration 👇</div>
          <div className="mt-20 text-center">&nbsp;</div>
          <div className="fw-bold fs-2 mt-5">Base</div>
          <div className="mt-20">
            Base is a secure, low-cost, builder-friendly Ethereum L2 built to bring the next billion users onchain.
          </div>
          <div className="mt-20">
            Base is incubated within Coinbase and plans to progressively decentralize in the years ahead. We believe
            that decentralization is critical to creating an open, global cryptoeconomy that is accessible to everyone.
          </div>
          <div className="fw-bold fs-2 mt-5">DIP Exchange</div>
          <div className="mt-20">
            DIP Exchange is a decentralized perpetual exchange that enables the creation of perpetual trading for any
            asset by anyone.
          </div>
          <div className="mt-20">
            DIP will begin its journey on Base network through ETH perpetual trading pairs and branch out to different
            chains where the highest liquidity pools lie.
          </div>
          <div className="mt-20 text-center">&nbsp;</div>
          <div className="fw-bold fs-2 mt-5">Migration Details</div>
          <div className="mt-10">#BTFD community’s most anticipated events, namely:</div>
          <div className="mt-20 ml-20">
            <div className="flex aligns-center mt-20">
              <div className="Activity-mark"></div>
              <div className="ml-10"> $DIP Fair Launch</div>
            </div>
            <div className="flex aligns-center mt-20">
              <div className="Activity-mark"></div>
              <div className="ml-10"> Liquidity Bootstrapping Event</div>
            </div>
            <div className="flex aligns-center mt-20">
              <div className="Activity-mark"></div>
              <div className="ml-10"> Beta Test Contest Rewards</div>
            </div>
          </div>
          <div className="fw-bold fs-2 mt-5">⚖️️ $DIP Fair Launch</div>
          <div className="mt-10">There will be no changes to the tokenomics of $DIP and $DGT.</div>
          <div className="mt-20">DIP/ETH LP pairs will be available on Sushiswap V2.</div>
          <div className="mt-20">
            🚨Contracts of $DIP will be officially announced on DIP Exchange’s social network sites, beware of scam
            contracts.🚨
          </div>
          <div className="fw-bold fs-2 mt-5">🧑‍🌾Liquidity Bootstrapping Event</div>
          <div>
            Liquidity Providers are asked to deposit
            <span className=" fw-bold">only ETH and USDC to Snr, Mezz, Jnr DLP pools, and ETH or DIP/ETH LP.</span>
          </div>
          <div className="fw-bold mt-5">Rules</div>
          <div className="mt-20">Liquidity providers are invited to deposit funds into any of the five pools.</div>
          <div className="mt-20 ml-20">
            <div className="flex aligns-center mt-20">
              <div className="Activity-mark"></div>
              <div className="ml-10">
                <span className=" fw-bold">DIP/ETH LP</span> (ETH or DIP/ETH LP only)
              </div>
            </div>
            <div className="flex aligns-center mt-20">
              <div className="Activity-mark"></div>
              <div className="ml-10">
                <span className=" fw-bold">Snr DLP</span> (ETH and USDC only)
              </div>
            </div>
            <div className="flex aligns-center mt-20">
              <div className="Activity-mark"></div>
              <div className="ml-10">
                <span className=" fw-bold">Mezz DLP</span> (ETH and USDC only)
              </div>
            </div>
            <div className="flex aligns-center mt-20">
              <div className="Activity-mark"></div>
              <div className="ml-10">
                <span className=" fw-bold">Jnr DLP</span> (ETH and USDC only)
              </div>
            </div>
          </div>
          <div className="mt-20">
            Total of 11,000,000 DIP will be airdropped across 6 weeks, rewarding the highest to Jnr DLP and the lowest
            to Snr DLP. It’s important to note that deposits are locked for the duration of this event.
          </div>
          <div className="mt-20">
            Note 1,000,000 DIP will be rewarded to Early Birds during the deposit period and 10,000,000 DIP during the
            lockup period (LBP).
          </div>
          <div className="mt-20">
            Depending on the pool, the locked LP’s will receive trading fee rewards and daily DIP incentives.
          </div>
          <div className="mt-20">
            With the trade mining program scheduled to take place simultaneously, we expect this event to be one of the
            highest yield generating event for LP’s.
          </div>
          <img
            src="https://miro.medium.com/v2/resize:fit:720/format:webp/1*iORAeJQ_ZaNGLVDTJ7uVYA.jpeg"
            width="100%"
            height="auto"
            className="Activity-img mt-20"
            alt=""
          ></img>
          <div className="fw-bold mt-5">Timeline</div>
          <div className="mt-20 ml-20">
            <div className="flex aligns-center mt-20">
              <div className="Activity-mark"></div>
              <div className="ml-10"> Lockup duration: Max. 6 weeks</div>
            </div>
            <div className="flex aligns-center mt-20">
              <div className="Activity-mark"></div>
              <div className="ml-10"> Deposits Open: August 15th 2023 2pm UTC</div>
            </div>
            <div className="flex aligns-center mt-20">
              <div className="Activity-mark"></div>
              <div className="ml-10"> Lockup period start: August 29th 2023 2pm UTC</div>
            </div>
            <div className="flex aligns-center mt-20">
              <div className="Activity-mark"></div>
              <div className="ml-10"> Lockup period ends: September 26th 2023 2pm UTC</div>
            </div>
            <div className="flex aligns-center mt-20">
              <div className="Activity-mark"></div>
              <div className="ml-10"> Total DIP allocation: 11,000,000 DIP</div>
            </div>
          </div>
          <div className="fw-bold fs-2 mt-5">🧑Beta Test Contest Rewards</div>
          <div>
            DIP Exchange recently opened beta test, and in just one month, we have received over 3,000 new registrants
            and generated approximately $30 million in volume, with each participant trading with $100.
          </div>
          <div className="mt-20">
            The eligible winners of Beta Test Contest on Fantom Testnet will be sent USDC on Base network, directly to
            each traders Metamask wallets. Users can check their wallets to confirm deposits on the day of mainnet
            trading goes live. Final statement of the winning trader’s wallets with the reward amount will be announced
            during distribution.
          </div>
          <div className="fw-bold mt-5">Eligibility Criteria</div>
          <div className="mt-20 ml-20">
            <div className="flex aligns-center mt-20">
              <div className="Activity-mark"></div>
              <div className="ml-10">
                Traders must have a positive earning upon the closing trading contest. (Traders are advised to close all
                position for the correct PnL to show on{" "}
                <a className="Activity-link-a" href="https://leaderboard.dip.exchange">
                  leaderboard.dip.exchange
                </a>
                ).
              </div>
            </div>
            <div className="flex aligns-center mt-20">
              <div className="Activity-mark"></div>
              <div className="ml-10">
                Traders must have more than $10 in profit to be counted in the 30,000 USD Prize Pool Distribution.
              </div>
            </div>
            <div className="flex aligns-center mt-20">
              <div className="Activity-mark"></div>
              <div className="ml-10">
                Traders must not have held or minted more than 10 FTM (testnet) for the duration of the trading contest.
              </div>
            </div>
          </div>
          <p />
          <EventBottom />
        </div>
      </div>
    </div>
  );
}

export default LbpActivity;
