import React from "react";
import "./ButtonWithIcon.css";

export default function ButtonWithIcon({ text, icon, onClick }) {
  return (
    <div className="Icon-button flex aligns-center justify-center" onClick={onClick}>
      <div>{text} </div>
      <img className="icon-xs ml-10" src={icon} alt=""></img>
    </div>
  );
}
