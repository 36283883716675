import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, EffectFade, Pagination } from "swiper";

import "./HomeActivity.css";
import "swiper/swiper-bundle.min.css";
import "swiper/components/effect-fade/effect-fade.min.css";
import "swiper/components/pagination/pagination.min.css";

SwiperCore.use([EffectFade, Autoplay, Pagination]);

export const HomeLastNews = (redirectPopupTimestamp, showRedirectModal) => {
  const datas = [
    {
      title: "Liquidity Bootstrapping Event",
      img: "https://miro.medium.com/v2/resize:fit:720/format:webp/1*Q9K2jyPl3TpzJ7QYt_tdZA.png",
      link: "https://blog.dip.exchange/liquidity-bootstrapping-event-bringing-liquidity-back-to-fantom-with-layerzero-5a6736837fcd",
    },
    // {
    //   title: "What is all this noise around DIP Exchange?",
    //   img: "https://miro.medium.com/v2/resize:fit:720/0*kJfWFP81Xv3AJSJY",
    //   link: "https://medium.com/dip-exchange/what-is-all-this-noise-around-dip-exchange-406d8349638c",
    // },
    {
      title: "DIP Exchange: 30,000 USDT Beta Test Trading Contest",
      img: require("img/beta-test-small.png"),
      link: "https://medium.com/@dip_exchange/dip-exchange-30-000-usdt-beta-test-trading-contest-4722fae3e401",
    },
    {
      title: "Join the DIP Exchange Zealy Contest",
      img: require("img/dip-zealy-contest.png"),
      link: "https://blog.dip.exchange/join-the-dip-exchange-zealy-contest-b304cdf786dd",
    },
    {
      title: "DIP Twitter Thread Contest",
      img: "https://miro.medium.com/v2/resize:fit:720/format:webp/1*WPBY_syxxKcxKemTDhaRog@2x.jpeg",
      link: "https://blog.dip.exchange/dip-twitter-thread-contest-9cbe283c6985",
    },
    {
      title: "Roadmap 2023",
      img: "https://miro.medium.com/v2/resize:fit:720/format:webp/1*kE9G4dW0srvE-R0E700ujQ.png",
      link: "https://blog.dip.exchange/roadmap-2023-37138b4d4a2b",
    },
  ];
  return (
    <div className="mt-40">
      <div className="default-container">
        <div className="Home-section-title-left">Latest News</div>

        <Swiper
          slidesPerView={3}
          spaceBetween={40}
          // onSlideChange={() => console.log("slide change")}
          // onSwiper={(swiper) => console.log(swiper)}
          autoplay={{ delay: 5000 }}
          loop
          breakpoints={{
            320: {
              slidesPerView: 1,
              slidesPerGroup: 1,
            },
            768: {
              slidesPerView: 2,
              slidesPerGroup: 1,
            },
            1000: {
              slidesPerView: 3,
              slidesPerGroup: 3,
            },
          }}
        // navigation
        // effect="fade"
        // fadeEffect={{ crossFade: true }}
        // pagination={{ clickable: true }}
        >
          {datas.map((data, index) => (
            <SwiperSlide>
              <div
                key={"homeevents" + index}
                onClick={() => {
                  window.open(data.link, "__blank");
                }}
              >
                <img src={data.img} alt="" width="100%" className="Home-Events-img"></img>
                <div className="Home-latest-news-card">{data.title} </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
