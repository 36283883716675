import statsIcon from "img/ic_total_volume.png";
import tradingIcon from "img/ic_open_interest.png";
import totaluserIcon from "img/ic_total_users.png";
import "./HomeTotal.css";
export const HomeTotalData = () => {
  const datas = [
    { title: "Trading Volume", value: "---", img: tradingIcon },
    { title: "Open Interest ", value: "---", img: statsIcon },
    { title: "Total Users  ", value: "---", img: totaluserIcon },
  ];
  return (
    <div className="">
      <div
        className="default-container row aligns-center justify-around"
        style={{ marginLeft: "auto", marginRight: "auto" }}
      >
        {datas.map((data, index) => (
          // <div className="Home-latest-info-block" key={index}>
          //   <img src={data.img} alt="Total Trading Volume Icon" className="Home-latest-info__icon" />
          //   <div className="Home-latest-info-content">
          //     <div className="Home-latest-info__title">{data.title}</div>
          //     <div className="Home-latest-info__value">{data.value}</div>
          //   </div>
          // </div>
          <div className="Home-total-card col-md-3 col-12 mt-20" key={index}>
            <div className="flex aligns-center">
              <img src={data.img} alt="Total Trading Volume Icon" className="Home-total-icon ml-10" />
              <div className="ml-20">
                <div className="Home-total-title">{data.title}</div>
                <div className="Home-total-value">{data.value}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
