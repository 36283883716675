import "./Activity.css";
import LaunchVideo from "video/launch.mp4";
import ButtonWithIcon from "components/Button/ButtonWithIcon";
import RightIcon from "img/ic_staking_jump.png";
import EventBottom from "./EventBottom";

function WebAppActivity ({ redirectPopupTimestamp, showRedirectModal }) {
  const learnMore = () => {
    window.open("https://blog.dip.exchange/launch-of-dip-exchange-web-app-d3559891512b", "__blank");
  };
  return (
    <div className="pb-30">
      <div className="Activity-bg">
        <div className="Activity-header">
          <div
            className=" default-container row justify-center aligns-center"
            style={{ marginRight: "auto", marginLeft: "auto" }}
          >
            <div className="col-12 col-md-7">
              <span className="Activity-title fw-bold">Launch of DIP Exchange Web App</span>
              <div className="Activity-sub-title mt-20">We are pleased to introduce a new feature for #BTFD fam, the DIP Exchange Web App.</div>
              <div className="mt-20">
                <span className="Activity-link" onClick={learnMore}>
                  learn more
                </span>
              </div>
              <div className="mt-20 flex aligns-center">
                <div className="flex-1 mr-5">
                  <a href="https://app.dip.exchange/">
                    <ButtonWithIcon variant="primary-action" text="Launch WebApp" icon={RightIcon} />
                  </a>
                </div>
                <div className="flex-1 ml-5">
                  {/* <Link to="/faucet">
                    <Button variant="secondary-action" className="w-100">
                      Get $100
                    </Button>
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-5 mt-20 ">
              <img
                src={require("img/bn_web_app.png")}
                alt="detail"
                width="100%"
                height="auto"
                className="Activity-img mt-20"
              ></img>
            </div>
          </div>
        </div>
      </div>
      <div className="default-container">
        <div className="Activity-content-group  ">
          <div className="Activity-content-title fs-2">Launch of DIP Exchange Web App</div>
          {/* <div className="waring-color mt-20">Event Period: August 11th 2023 2pm UTC - September 24th 2023 2pm UTC</div> */}
          <div className="mt-20 ">
            We are pleased to introduce a new feature for #BTFD fam, the DIP Exchange Web App.
            <div className="text-center">
              <img
                src="https://miro.medium.com/v2/resize:fit:574/format:webp/1*Fi3o7STFK-AqrxsxKapeSg.png"
                width="25%"
                height="auto"
                className="Activity-img mt-20"
                alt=""
              ></img>
            </div>
            <div className="mt-20">
              See instructions below on installing the Web App on your HomeScreen.
            </div>
          </div>
          <div className="fw-bolder">
            <div className="mt-20 ">
              Step 1: Open Safari and visit app.dip.exchange
            </div>
            <div className="mt-20  ">
              Step 2: Click on more options icon at the bottom
            </div>
            <div className="mt-20  ">
              Step 3: Click Add to Home Screen
            </div>
            <div className="mt-20  ">
              Step 4: Click Add
            </div>
            <div className="mt-20  ">
              Step 5: Find DIP Exchange Web App on your Home Screen
            </div>
            <div className="mt-20 fw-bolder">
              Step 6: Connect your Metamask Wallet
            </div> </div>

          <EventBottom />
        </div>
      </div>
    </div>
  );
}

export default WebAppActivity;
