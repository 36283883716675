import React from "react";

import "./Quote.css";

import useSWR from "swr";
import cx from "classnames";
import { formatMoney, formatPercent } from "lib/numbers";
import { getTokenIcon } from "config/icons";
import { getQuoteLink } from "config/links";
import { useChainId } from "lib/chains";
export default function Quote(props) {
  const { title, isHome, trading24H } = props;
  const { chainId } = useChainId();
  const { data: quotes } = useSWR([getQuoteLink(chainId)], {
    fetcher: (...args) => fetch(...args).then((res) => res.json()),
  });

  const cellGroupClass = cx("flex", "justify-between", "hidden-x", "aligns-center");
  const getDecimals = (symbol, isPrice) => {
    switch (symbol) {
      case "FTMUSDT":
        return isPrice ? 4 : 2;
      default:
        return 2;
    }
  };
  const get24HData = (symbol, price) => {
    if (!trading24H) return;
    symbol = symbol.split("USDT")[0];
    for (let token24H of trading24H) {
      if (token24H[symbol]) {
        const value = token24H[symbol].value;
        const volume = value / price;
        return { value: formatMoney(value, 2), volume: formatMoney(volume, 4) };
      } else {
        return { value: "---", volume: "---" };
      }
    }
  };
  const cellTitles = [
    { title: "Pair" },
    { title: "Last Price" },
    { title: "24h Change" },
    { title: "24h High", isHiddenInMin: true },
    { title: "24h Low", isHiddenInMin: true },
    // { title: "24h Volume", isHiddenInMin: true },
    // { title: "24h Volume(USD)", isHiddenInMin: true },
  ];
  return (
    <div className={cx({ Quote: isHome })}>
      <div className={cx({ "default-container": isHome })}>
        {title && <div className={isHome ? "Home-section-title" : "Page-title"}>{title}</div>}
        <div className="Quote-bg">
          <div className="CellTitle ">
            <div className={cellGroupClass}>
              {cellTitles.map((item, index) => (
                <div
                  key={index}
                  className={cx("flex-1", {
                    "CellTitle-hidden": item.isHiddenInMin,
                    "CellTitle-change": index === 2,
                    "CellTitle-price": index === 1,
                  })}
                >
                  {item.title}
                </div>
              ))}
            </div>
          </div>
          {quotes?.map((item, index) => (
            <div key={index} className="mt-10 cell">
              <div className={cellGroupClass}>
                <div className="flex aligns-center  flex-1">
                  <img className="icon" src={getTokenIcon(item.symbol)} alt={item.symbol}></img>
                  <div>{item.symbol.split("USDT")[0] + "/USD"}</div>
                </div>
                <div
                  className={cx("flex-1 CellTitle-price", {
                    "up-color": item.lastPrice > item.openPrice,
                    "down-color": item.lastPrice < item.openPrice,
                  })}
                >
                  ${formatMoney(item.lastPrice, getDecimals(item.symbol, true))}
                </div>
                <div
                  className={cx(" CellTitle-change flex-1", {
                    "up-color": item.lastPrice > item.openPrice,
                    "down-color": item.lastPrice < item.openPrice,
                  })}
                >
                  {formatPercent(item.lastPrice, item.openPrice)}
                </div>
                <div className="CellTitle-hidden flex-1">
                  ${formatMoney(item.highPrice, getDecimals(item.symbol, true))}
                </div>
                <div className="CellTitle-hidden flex-1">
                  ${formatMoney(item.lowPrice, getDecimals(item.symbol, true))}
                </div>
                {/* <div className="CellTitle-hidden flex-1">{formatMoney(item.volume, getDecimals(item.symbol))}</div> */}
                {/* <div className="CellTitle-hidden flex-1">${formatMoney(item.quoteVolume, getDecimals(item.symbol))}</div> */}
                {/* <div className="CellTitle-hidden flex-1">{get24HData(item.symbol, item.lastPrice)?.volume}</div>
                <div className="CellTitle-hidden flex-1">${get24HData(item.symbol, item.lastPrice)?.value}</div> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
