import {
  ARBITRUM,
  ARBITRUM_TESTNET,
  AVALANCHE,
  AVALANCHE_FUJI,
  FANTOM,
  FANTOM_TESTNET,
  BASE,
  BASE_GOERLI,
} from "config/chains";
import arbitrum from "img/ic_arbitrum_24.svg";
import avalanche from "img/ic_avalanche_24.svg";
import avalancheTestnet from "img/ic_avalanche_testnet_24.svg";

import gmxIcon from "img/ic_gmx_40.svg";
import glpIcon from "img/ic_glp_40.svg";
import gmxArbitrum from "img/ic_gmx_arbitrum.svg";
import gmxAvax from "img/ic_gmx_avax.svg";
import glpArbitrum from "img/ic_glp_arbitrum.svg";
import glpAvax from "img/ic_glp_avax.svg";
import BTCIcon from "img/BTC.png";
import ETHIcon from "img/ETH.png";
import FTMIcon from "img/FTM.png";
import DIPIcon from "img/DIP.png";
import USDTIcon from "img/ic_usdt_24.svg";
import DGTIcon from "img/dgt-white.png";
import BASEIcon from "img/ic_base.png";

const ICONS = {
  [FANTOM]: {
    network: FTMIcon,
    gmx: DIPIcon,
    glp: DGTIcon,
  },
  [BASE]: {
    network: BASEIcon,
    gmx: DIPIcon,
    glp: DGTIcon,
  },
  [BASE_GOERLI]: {
    network: BASEIcon,
    gmx: DIPIcon,
    glp: DGTIcon,
  },
  [ARBITRUM]: {
    network: arbitrum,
    gmx: DIPIcon,
    glp: DGTIcon,
  },
  [AVALANCHE]: {
    network: avalanche,
    gmx: DIPIcon,
    glp: DGTIcon,
  },
  [ARBITRUM_TESTNET]: {
    network: arbitrum,
  },
  [FANTOM_TESTNET]: {
    network: FTMIcon,
  },
  [AVALANCHE_FUJI]: {
    network: avalancheTestnet,
  },
  common: {
    gmx: DIPIcon,
    glp: DGTIcon,
  },
};

export function getIcon(chainId: number | "common", label: string) {
  if (chainId in ICONS) {
    if (label in ICONS[chainId]) {
      return ICONS[chainId][label];
    }
  }
}
export function getIcons(chainId: number | "common") {
  if (!chainId) return;
  if (chainId in ICONS) {
    return ICONS[chainId];
  }
}

export function getTokenIcon(symbol: String) {
  let lowerSymbol = symbol.toLowerCase();
  if (lowerSymbol == "usdt") {
    return USDTIcon;
  }
  let tempSymbol = lowerSymbol.split("usdt")[0].split("usd")[0];
  switch (tempSymbol) {
    case "btc":
      return BTCIcon;
    case "eth":
      return ETHIcon;
    case "ftm":
      return FTMIcon;
    case "usdt":
      return USDTIcon;
    case "link":
      return "https://assets.coingecko.com/coins/images/877/thumb/chainlink-new-logo.png?1547034700";
    case "comp":
      return "https://assets.coingecko.com/coins/images/10775/small/COMP.png?1592625425";
    default:
      return BTCIcon;
  }
}
