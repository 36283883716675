import React, { useEffect, useState } from "react";
import "./Activity.css";
import BetaTestImg from "img/beta-trade-contest.png";
import Button from "components/Button/Button";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import DiscordImg from "img/ic_discord_blue.png";
import { bindDiscord, dcUserInfoUrl } from "lib/api";
import useWallet from "lib/wallets/useWallet";
import { helperToast } from "lib/helperToast";
import { isLocal } from "config/env";

function Activity () {
  const [dcUserInfo, setDcUserInfo] = useState({ avatarUrl: undefined, userName: undefined });
  const { account } = useWallet();

  const history = useHistory();
  const gotoFaucet = () => {
    history.push("/faucet");
  };
  const learnMore = () => {
    window.open("https://medium.com/@dip_exchange/395add03d86", "__blank");
  };

  const clickVerificationDiscord = () => {
    if (!account) {
      helperToast.error("Please connect wallet!");
      return;
    }
    if (!dcUserInfo.userName) bindDiscord(account);
    else helperToast.error("Already Verification Discord!");
  };

  useEffect(() => {
    try {
      if (account) {
        fetch(`${dcUserInfoUrl}/${account}`).then((res) => {
          if (res.status === 200) {
            res.json().then((data) => {
              setDcUserInfo(data.errno === 0 ? data.data : { avatarUrl: undefined, userName: undefined });
            });
          }
        });
      }
    } catch (e) { }
  }, [account]);
  return (
    <div className="pb-30">
      <div className="Activity-bg">
        <div className="Activity-header">
          <div
            className=" default-container row justify-center aligns-center"
            style={{ marginRight: "auto", marginLeft: "auto" }}
          >
            <div className="col-12 col-md-7">
              <div className="Activity-title fw-bold">BETA TEST TRADING CONTEST</div>
              <div className="Activity-sub-title mt-20">Win Big from the 30,000 USDT Prize Pool!</div>
              <div className="mt-10">
                Participate to gain early trial access.
                <span className="Activity-link" onClick={learnMore}>
                  learn more
                </span>
              </div>
              <div className="mt-20 flex aligns-center">
                <div className="flex-1 mr-5">
                  <Link to="/markets">
                    <Button variant="primary-action" className="w-100">
                      Launch Trade
                    </Button>
                  </Link>
                </div>
                <div className="flex-1 ml-5">
                  {/* <Link to="/faucet">
                    <Button variant="secondary-action" className="w-100">
                      Get $100
                    </Button>
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-5 mt-20 ">
              <img src={BetaTestImg} width="100%" height="auto" className="Activity-img" alt=""></img>
            </div>
          </div>
        </div>
      </div>
      <div className="default-container">
        <div className="Activity-content-group  ">
          <div className="Activity-content-title">DIP Exchange: 30,000 USDT Beta Test Trading Contest</div>
          <div className="Activity-content-sub-title mt-10">Event Period: July 11, 2023 - August 10, 2023</div>
          <div className=" waring-color  mt-20 fw-bold">Event Details</div>
          <div className="mt-10">
            ▶ <span className="fw-bold">Prize Pool:</span> 30,000 USDT
          </div>
          <div className="mt-10">
            ▶ <span className="fw-bold">Network:</span> Fantom Testnet
          </div>
          <div className=" waring-color  mt-20 fw-bold">Rules</div>
          <div className="mt-10">
            ▶ <span className="fw-bold">User Limit:</span> First 3,000 registered users only (FCFS)
            <p />▶ <span className="fw-bold">Leverage:</span> Up to 50x <p />
            <p />▶ <span className="fw-bold">Max per Wallet:</span> of 100 USDT (testnet) can be claimed
            <span style={{ color: "rgb(255,255,255,0.5)" }}>
              (click&nbsp;
              <span
                className="Activity-link"
                onClick={() => {
                  gotoFaucet();
                }}
              >
                here
              </span>
              &nbsp; to claimed)
            </span>
            <p /> ▶<span className="fw-bold">Verification:</span> Users must sync in their wallet with Discord ID
            <p />
            <div className="mt-10" style={{ display: "inline-block" }}>
              <div
                className="Activity-discord flex aligns-center"
                onClick={() => {
                  clickVerificationDiscord();
                }}
              >
                <img src={DiscordImg} alt="discord" className="icon-sm"></img>
                {dcUserInfo.avatarUrl && <img src={dcUserInfo.avatarUrl} alt="discord" className="icon-sm ml-10"></img>}
                <div className="ml-10">
                  {dcUserInfo.userName
                    ? `Hi ${dcUserInfo.userName}, Your discord verification success!`
                    : "Click here to Verification your Discord Account!"}
                </div>
              </div>
            </div>
          </div>
          <div className=" waring-color  mt-20 fw-bold">Payout</div>
          <div className="mt-10">
            ▶ <span className="fw-bold">Distribution:</span> Upon launch of mainnet, credited directly to your wallets
            <p />▶ <span className="fw-bold">Amount:</span> Ranking based on % performance
          </div>
          <div className=" waring-color  mt-20 fw-bold">Others</div>
          <div className="mt-10">
            A friendly ask from DIP Exchange team is to prevent signing up with duplicate accounts and give fair
            opportunity to all participants.
            <p />
            In addition to the ask above, we encourage users to share your trade positions through the app and help us
            spread our Perp DEX! <p />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Activity;
