import React from "react";
import { HeaderLink } from "components/Header/HeaderLink";
import LiquidityCard from "components/LiquidityCard/LiquidityCard";
import useWallet from "lib/wallets/useWallet";
import SnrIcon from "img/ic_snr.png";
import JnrIcon from "img/ic_jnr.png";
import MezzIcon from "img/ic_mezz.png";
import { useChainId } from "lib/chains";
import { getContract } from "config/contracts";
import { contractFetcher } from "lib/contracts";
import useSWR from "swr";
import ERC20 from "abis/ERC20.json";

import { getTokenBySymbol } from "config/tokens";
import { getPoolData } from "pages/Earn/services";
import { useDipPairPrice } from "domain/prices";
import { useDLPPrices } from "domain/pool";
import { formatMoney } from "lib/numbers";
import RiskTip from "components/LiquidityCard/RiskTip";
import cx from "classnames";

function Liquiditys ({ isHome, showRedirectModal, redirectPopupTimestamp }) {
  const { active, signer } = useWallet();
  const { chainId } = useChainId();
  const dipMasterAddr = getContract(chainId, "DIPMaster");

  const { data: snrManagementData } = useSWR(
    [`Earn:SnrBalance${active}`, chainId, getTokenBySymbol(chainId, "SnrDLP").address, "balanceOf", dipMasterAddr],
    {
      fetcher: contractFetcher(signer, ERC20),
    }
  );

  const { data: mezzManagementData } = useSWR(
    [`Earn:MezzBalance${active}`, chainId, getTokenBySymbol(chainId, "MezzDLP").address, "balanceOf", dipMasterAddr],
    {
      fetcher: contractFetcher(signer, ERC20),
    }
  );

  const { data: jnrManagementData } = useSWR(
    [`Earn:JnrBalance${active}`, chainId, getTokenBySymbol(chainId, "JnrDLP").address, "balanceOf", dipMasterAddr],
    {
      fetcher: contractFetcher(signer, ERC20),
    }
  );
  // const { data: lpManagementData } = useSWR(
  //   [
  //     `Earn:DIP/FTMLPBalance${active}`,
  //     chainId,
  //     getTokenBySymbol(chainId, "DIP/ETH LP").address,
  //     "balanceOf",
  //     dipMasterAddr,
  //   ],
  //   {
  //     fetcher: contractFetcher(signer, ERC20),
  //   }
  // );

  const pairPrice = useDipPairPrice(chainId, signer);
  const dlpPrice = useDLPPrices(chainId, signer);
  const snrPool = getPoolData(snrManagementData, 0, dlpPrice?.snrDLPPrice, pairPrice?.dipPrice);
  const mezzPool = getPoolData(mezzManagementData, 1, dlpPrice?.mezzDLPPrice, pairPrice?.dipPrice);
  const jnrPool = getPoolData(jnrManagementData, 2, dlpPrice?.jnrDLPPrice, pairPrice?.dipPrice);

  const liquiditys = [
    {
      title: "Senior    Tranche",
      risk: 1,
      management: `${snrPool?.totalValue ? snrPool?.totalValue : "---"}`,
      apr: snrPool?.apr ? formatMoney(snrPool?.apr, 2) : "---",
      icons: [SnrIcon],
    },
    {
      title: "Mezzanine Tranche",
      management: `${mezzPool?.totalValue ? mezzPool?.totalValue : "---"}`,
      apr: mezzPool?.apr ? formatMoney(mezzPool?.apr, 2) : "---",
      icons: [MezzIcon],
    },
    {
      title: "Junior    Tranche",
      risk: 3,
      management: `${jnrPool?.totalValue ? jnrPool?.totalValue : "---"}`,
      apr: jnrPool?.apr ? formatMoney(jnrPool?.apr, 2) : "---",
      icons: [JnrIcon],
    },
  ];
  return (
    <div>
      <div className={isHome ? "Home-section-title" : "Page-title"}>Liquidity</div>
      <div className="row">
        <div className="col-md-4 Dashboard-liquidity-left ">
          <div className="">
            <img src={require("img/dashboard_staking.png")} alt="staking" className="mt-40"></img>
            <div className="mt-20 ml-10 mr-10 mb-10">
              Deposit any asset to one or more tranches to start earning protocol revenues.
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <div className=" Dashboard-liquidity-card">
            {liquiditys.map((item, index) => (
              <div className="mt-10" key={index}>
                <HeaderLink
                  to="/earn"
                  redirectPopupTimestamp={redirectPopupTimestamp}
                  showRedirectModal={showRedirectModal}
                >
                  <div className="Liquidity-card">
                    <div className="flex aligns-center">
                      {item.icons
                        ? item.icons.map((icon, index) => (
                          <img
                            key={index}
                            alt=""
                            src={icon}
                            className={cx("icon-sm", {
                              " mr-base": index === item.icons.length - 1,
                              "DefaultCard--ml10": index > 0,
                            })}
                          ></img>
                        ))
                        : ""}
                      <div className="flex-1">
                        <div className="flex justify-between aligns-center">
                          <div className="flex">
                            <div className="Liquidity-card-title">{item.title}</div>
                            {item.risk && <RiskTip type={item.risk}></RiskTip>}
                          </div>
                          <div>
                            <img src={require("img/ic_staking_jump.png")} alt=""></img>
                          </div>
                        </div>
                        {/* <div className=" Liquidity-card-split"></div> */}
                        <div className="flex aligns-center justify-between mt-10">
                          <div className="Liquidity-card-subtitle">APR:</div>
                          <div className="Liquidity-card-subtitle">{item.apr}%</div>
                        </div>
                        <div className="flex aligns-center justify-between">
                          <div className="Liquidity-card-subtitle">Assets Under Management:</div>
                          <div className="Liquidity-card-subtitle">${item.management}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </HeaderLink>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Liquiditys;
