import React from "react";
import Footer from "components/Footer/Footer";
import "./Home.css";

import descLeftImg from "img/Home-desc-left.png";

import { HomeSwiper } from "./HomeSwiper";
import useSWR from "swr";

import { USD_DECIMALS, getTotalVolumeSum } from "lib/legacy";

import { useUserStat } from "domain/legacy";

import TokenCard from "components/TokenCard/TokenCard";
import { Trans } from "@lingui/macro";
import { HeaderLink } from "components/Header/HeaderLink";
import { ARBITRUM, AVALANCHE } from "config/chains";
import { getServerUrl } from "config/backend";
import Quote from "components/Quote/Quote";
import { bigNumberify, formatAmount, numberWithCommas } from "lib/numbers";
import Liquiditys from "components/Dashboard/Liquiditys";
import Stakings from "components/Dashboard/Stakings";
import { HomeLastNews } from "./HomeLastNews";
import { Link } from "react-router-dom";
import { HomeTotalData } from "./HomeTotalData";

export default function Home ({ showRedirectModal, redirectPopupTimestamp }) {
  // const [openedFAQIndex, setOpenedFAQIndex] = useState(null)
  // const faqContent = [{
  //   id: 1,
  //   question: "What is GMX?",
  //   answer: "GMX is a decentralized spot and perpetual exchange that supports low swap fees and zero price impact trades.<br><br>Trading is supported by a unique multi-asset pool that earns liquidity providers fees from market making, swap fees, leverage trading (spreads, funding fees & liquidations), and asset rebalancing.<br><br>Dynamic pricing is supported by Chainlink Oracles along with TWAP pricing from leading volume DEXs."
  // }, {
  //   id: 2,
  //   question: "What is the GMX Governance Token? ",
  //   answer: "The GMX token is the governance token of the GMX ecosystem, it provides the token owner voting rights on the direction of the GMX platform.<br><br>Additionally, when GMX is staked you will earn 30% of the platform-generated fees, you will also earn Escrowed GMX tokens and Multiplier Points."
  // }, {
  //   id: 3,
  //   question: "What is the GLP Token? ",
  //   answer: "The GLP token represents the liquidity users provide to the GMX platform for Swaps and Margin Trading.<br><br>To provide liquidity to GLP you <a href='https://gmx.io/buy_glp' target='_blank'>trade</a> your crypto asset BTC, ETH, LINK, UNI, USDC, USDT, MIM, or FRAX to the liquidity pool, in exchange, you gain exposure to a diversified index of tokens while earning 50% of the platform trading fees and esGMX."
  // }, {
  //   id: 4,
  //   question: "What can I trade on GMX? ",
  //   answer: "On GMX you can swap or margin trade any of the following assets: ETH, BTC, LINK, UNI, USDC, USDT, MIM, FRAX, with others to be added. "
  // }]

  // const toggleFAQContent = function(index) {
  //   if (openedFAQIndex === index) {
  //     setOpenedFAQIndex(null)
  //   } else {
  //     setOpenedFAQIndex(index)
  //   }
  // }
  // console.log("home", showRedirectModal);

  const productInfoLeft = [
    {
      image: require("img/ic_ecosystem.png"),
      title: "Strong ecosystem",
      subTitle: "DIP builds a powerful trading ecosystem around decentralized trading",
    },
    {
      image: require("img/ic_safe.png"),
      title: "Safe Decentralization",
      subTitle:
        "Fully decentralized, audited contracts and open source. Use ChainLink as an oracle to ensure price stability",
    },
    {
      image: require("img/ic_dao.png"),
      title: "Operated by DAOs",
      subTitle: "A centralized derivatives protocol created, operated and owned by  DIP DAO",
    },
  ];
  const productInfoRight = [
    {
      image: require("img/ic_risk.png"),
      title: "Minimize the risk of LP holders",
      subTitle:
        "Share the risk of LP holders through different liquidity pools of Senior Tranche/Mezzanine Tranche/Junior Tranche",
    },
    {
      image: require("img/ic_50x-leverage.png"),
      title: "High degree of freedom leverage trading",
      subTitle: "Support up to 50x leverage",
    },
    {
      image: require("img/ic_multiple-benefits.png"),
      title: "Multiple benefits",
      subTitle: "Provide liquidity, and you can get DIP token rewards",
    },
  ];

  // ARBITRUM

  const arbitrumPositionStatsUrl = getServerUrl(ARBITRUM, "/position_stats");
  const { data: arbitrumPositionStats } = useSWR([arbitrumPositionStatsUrl], {
    fetcher: (...args) => fetch(...args).then((res) => res.json()),
  });

  const arbitrumTotalVolumeUrl = getServerUrl(ARBITRUM, "/total_volume");
  const { data: arbitrumTotalVolume } = useSWR([arbitrumTotalVolumeUrl], {
    fetcher: (...args) => fetch(...args).then((res) => res.json()),
  });

  // AVALANCHE

  const avalanchePositionStatsUrl = getServerUrl(AVALANCHE, "/position_stats");
  const { data: avalanchePositionStats } = useSWR([avalanchePositionStatsUrl], {
    fetcher: (...args) => fetch(...args).then((res) => res.json()),
  });

  const avalancheTotalVolumeUrl = getServerUrl(AVALANCHE, "/total_volume");
  const { data: avalancheTotalVolume } = useSWR([avalancheTotalVolumeUrl], {
    fetcher: (...args) => fetch(...args).then((res) => res.json()),
  });

  // Total Volume

  const arbitrumTotalVolumeSum = getTotalVolumeSum(arbitrumTotalVolume);
  const avalancheTotalVolumeSum = getTotalVolumeSum(avalancheTotalVolume);

  let totalVolumeSum = bigNumberify(0);
  if (arbitrumTotalVolumeSum && avalancheTotalVolumeSum) {
    totalVolumeSum = totalVolumeSum.add(arbitrumTotalVolumeSum);
    totalVolumeSum = totalVolumeSum.add(avalancheTotalVolumeSum);
  }

  // Open Interest

  let openInterest = bigNumberify(0);
  if (
    arbitrumPositionStats &&
    arbitrumPositionStats.totalLongPositionSizes &&
    arbitrumPositionStats.totalShortPositionSizes
  ) {
    openInterest = openInterest.add(arbitrumPositionStats.totalLongPositionSizes);
    openInterest = openInterest.add(arbitrumPositionStats.totalShortPositionSizes);
  }

  if (
    avalanchePositionStats &&
    avalanchePositionStats.totalLongPositionSizes &&
    avalanchePositionStats.totalShortPositionSizes
  ) {
    openInterest = openInterest.add(avalanchePositionStats.totalLongPositionSizes);
    openInterest = openInterest.add(avalanchePositionStats.totalShortPositionSizes);
  }

  // user stat
  const arbitrumUserStats = useUserStat(ARBITRUM);
  const avalancheUserStats = useUserStat(AVALANCHE);
  let totalUsers = 0;

  if (arbitrumUserStats && arbitrumUserStats.uniqueCount) {
    totalUsers += arbitrumUserStats.uniqueCount;
  }

  if (avalancheUserStats && avalancheUserStats.uniqueCount) {
    totalUsers += avalancheUserStats.uniqueCount;
  }

  const LaunchExchangeButton = () => {
    return (
      <HeaderLink
        className="default-btn"
        to="/markets"
        redirectPopupTimestamp={redirectPopupTimestamp}
        showRedirectModal={showRedirectModal}
      >
        <Trans>Launch App</Trans>
      </HeaderLink>
    );
  };

  return (
    <div className="Home">
      <div className="Home-header-bg"></div>
      {/* <div className="home-bg"> */}
      <HomeSwiper redirectPopupTimestamp={redirectPopupTimestamp} showRedirectModal={showRedirectModal} />
      {/* <div className="Home-top-image"></div> */}
      {/* <div className="Home-title-section-container default-container">
          <div className="Home-title-section">
            <div className="Home-title">
              <Trans>Better Decentralized Perpetual Exchange</Trans>
            </div>
            <div className="Home-description">
              <Trans>
                Trade BTC, ETH, FTM and other top
                <br />
                cryptocurrencies with up to 50x leverage directly from your wallet
              </Trans>
            </div>
            <LaunchExchangeButton />
          </div>
      {/* </div> */}
      <HomeTotalData />

      {/* <HomeLastNews /> */}
      <Quote title="Let's trade" isHome={"true"} />
      {/* </div> */}

      {/* <div className="Home-second"> */}
      <div className="Home-default-section">
        <div className="default-container Home-desc-cell-card">
          <div className="Home-section-title">Push beyond the limits of DEX’s imagination</div>
          {/* <div className="Home-section-sub-title">Welcome to the kingdom of leveraged trading</div> */}
          <div className="row aligns-center justify-center">
            <div className="col-12 col-md-4">
              {productInfoLeft.map((item, index) => (
                <div className="Home-desc-cell flex aligns-center" key={index}>
                  <img className="Home-desc-icon" src={item.image} alt="home-desc-icon" />
                  <div className="flex-col ml-20">
                    <div className="Home-desc-cell-title">{item.title}</div>
                    <div className="Home-desc-cell-subtitle">{item.subTitle}</div>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-12 col-md-4">
              <img src={descLeftImg} className="Home-desc-left" alt="DIP desc" />
            </div>
            <div className="col-12 col-md-4">
              {productInfoRight.map((item, index) => (
                <div className="Home-desc-cell flex aligns-center" key={index}>
                  <img className="Home-desc-icon" src={item.image} alt="home-desc-icon" />
                  <div className="flex-col ml-20">
                    <div className="Home-desc-cell-title">{item.title}</div>
                    <div className="Home-desc-cell-subtitle">{item.subTitle}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="Home-default-section">
        <div className="default-container">
          <div className="Home-section-title">Ecosystem Token</div>
          <TokenCard showRedirectModal={showRedirectModal} redirectPopupTimestamp={redirectPopupTimestamp} />
        </div>
      </div>
      <div className="Home-default-section">
        <div className="default-container">
          <Liquiditys
            isHome={"true"}
            showRedirectModal={showRedirectModal}
            redirectPopupTimestamp={redirectPopupTimestamp}
          />
        </div>
      </div>
      <div className="Home-default-section">
        <div className="default-container">
          <Stakings
            isHome={"true"}
            showRedirectModal={showRedirectModal}
            redirectPopupTimestamp={redirectPopupTimestamp}
          />
        </div>
      </div>

      {/* <div className="Home-benefits-section">
        <div className="Home-benefits default-container">
          <div className="Home-benefit">
            <div className="Home-benefit-icon">
              <img src={liquidityIcon} alt="Reduce Liquidation Risks Icon" className="Home-benefit-icon-symbol" />
              <div className="Home-benefit-title">
                <Trans>Reduce Liquidation Risks</Trans>
              </div>
            </div>
            <div className="Home-benefit-description">
              <Trans>
                An aggregate of high-quality price feeds determine when liquidations occur. This keeps positions safe
                from temporary wicks.
              </Trans>
            </div>
          </div>
          <div className="Home-benefit">
            <div className="Home-benefit-icon">
              <img src={costIcon} alt="Save on Costs Icon" className="Home-benefit-icon-symbol" />
              <div className="Home-benefit-title">
                <Trans>Save on Costs</Trans>
              </div>
            </div>
            <div className="Home-benefit-description">
              <Trans>
                Enter and exit positions with minimal spread and zero price impact. Get the optimal price without
                incurring additional costs.
              </Trans>
            </div>
          </div>
          <div className="Home-benefit">
            <div className="Home-benefit-icon">
              <img src={simpleSwapIcon} alt="Simple Swaps Icon" className="Home-benefit-icon-symbol" />
              <div className="Home-benefit-title">
                <Trans>Simple Swaps</Trans>
              </div>
            </div>
            <div className="Home-benefit-description">
              <Trans>
                Open positions through a simple swap interface. Conveniently swap from any supported asset into the
                position of your choice.
              </Trans>
            </div>
          </div>
        </div>
      </div>
      <div className="Home-cta-section">
        <div className="Home-cta-container default-container">
          <div className="Home-cta-info">
            <div className="Home-cta-info__title">
              <Trans>Available on your preferred network</Trans>
            </div>
            <div className="Home-cta-info__description">
              <Trans>GMX is currently live on Arbitrum and Avalanche.</Trans>
            </div>
          </div>
          <div className="Home-cta-options">
            <div className="Home-cta-option Home-cta-option-arbitrum">
              <div className="Home-cta-option-icon">
                <img src={arbitrumIcon} width="96" alt="Arbitrum Icon" />
              </div>
              <div className="Home-cta-option-info">
                <div className="Home-cta-option-title">Arbitrum</div>
                <div className="Home-cta-option-action">
                  <LaunchExchangeButton />
                </div>
              </div>
            </div>
            <div className="Home-cta-option Home-cta-option-ava">
              <div className="Home-cta-option-icon">
                <img src={avaxIcon} width="96" alt="Avalanche Icon" />
              </div>
              <div className="Home-cta-option-info">
                <div className="Home-cta-option-title">Avalanche</div>
                <div className="Home-cta-option-action">
                  <LaunchExchangeButton />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="Home-video-section">
        <div className="Home-video-container default-container">
          <div className="Home-video-block">
            <img src={gmxBigIcon} alt="gmxbig" />
          </div>
        </div>
      </div> */}
      {/* <div className="Home-faqs-section">
        <div className="Home-faqs-container default-container">
          <div className="Home-faqs-introduction">
            <div className="Home-faqs-introduction__title">FAQs</div>
            <div className="Home-faqs-introduction__description">Most asked questions. If you wish to learn more, please head to our Documentation page.</div>
            <a href="https://gmxio.gitbook.io/gmx/" className="default-btn Home-faqs-documentation">Documentation</a>
          </div>
          <div className="Home-faqs-content-block">
            {
              faqContent.map((content, index) => (
                <div className="Home-faqs-content" key={index} onClick={() => toggleFAQContent(index)}>
                  <div className="Home-faqs-content-header">
                    <div className="Home-faqs-content-header__icon">
                      {
                        openedFAQIndex === index ? <FiMinus className="opened" /> : <FiPlus className="closed" />
                      }
                    </div>
                    <div className="Home-faqs-content-header__text">
                      { content.question }
                    </div>
                  </div>
                  <div className={ openedFAQIndex === index ? "Home-faqs-content-main opened" : "Home-faqs-content-main" }>
                    <div className="Home-faqs-content-main__text">
                      <div dangerouslySetInnerHTML={{__html: content.answer}} >
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div> */}
      <Footer showRedirectModal={showRedirectModal} redirectPopupTimestamp={redirectPopupTimestamp} />
      {/* </div> */}
    </div>
  );
}
