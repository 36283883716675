import { HeaderLink } from "./HeaderLink";
import { useState } from "react";
import dropDownIcon from "img/ic_dropdown.png";
import { useHistory } from "react-router-dom";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { isHomeSite, getAppBaseUrl } from "lib/legacy";
import { shouldShowRedirectModal } from "lib/legacy";

export const HeaderDropdownList = ({
  small,
  redirectPopupTimestamp,
  showRedirectModal,
  subList,
  mainTitle,
  isHomeLink,
}) => {
  const history = useHistory();

  let subMenuTimer;

  const isOnHomePage = window.location.pathname === "/";
  const isHome = isHomeSite();

  const [isEarnHovered, setIsEarnHovered] = useState(false);
  const hoverSubMenu = (isShow) => {
    if (isShow) {
      clearTimeout(subMenuTimer);
      setIsEarnHovered(true);
    } else {
      subMenuTimer = setTimeout(() => {
        setIsEarnHovered(false);
      }, 200);
    }
  };
  const click = (isExternal, path) => {
    setIsEarnHovered(false);
    if (isExternal) {
      if (shouldShowRedirectModal(redirectPopupTimestamp)) {
        showRedirectModal(path);
      } else {
        window.open(path, "_blank");
      }
    } else {
      if (isHome && !(isHomeLink && !isOnHomePage)) {
        const baseUrl = getAppBaseUrl();
        if (shouldShowRedirectModal(redirectPopupTimestamp)) {
          showRedirectModal(baseUrl + path);
        } else {
          window.open(baseUrl + path);
        }
      } else {
        history.push(path);
      }
    }
  };
  return (
    <div>
      {small ? (
        subList.map((item, index) => (
          <div className="App-header-link-container" key={index}>
            {item.url && <ExternalLink href={item.url}> {item.title}</ExternalLink>}
            {item.path && (
              <HeaderLink
                to={item.path}
                isHomeLink={isHomeLink}
                redirectPopupTimestamp={redirectPopupTimestamp}
                showRedirectModal={showRedirectModal}
              >
                {item.title}
              </HeaderLink>
            )}
          </div>
        ))
      ) : (
        <div
          className="App-header-link-container"
          onMouseEnter={() => {
            hoverSubMenu(true);
          }}
          onMouseLeave={() => {
            hoverSubMenu(false);
          }}
        >
          <div className="a">
            <span>{mainTitle}</span>
            <img src={dropDownIcon} alt="dropdown" className="ml-5 icon-ss"></img>
          </div>
          {isEarnHovered && (
            <div
              className="App-header-dropdown"
              onMouseEnter={() => {
                hoverSubMenu(true);
              }}
              onMouseLeave={() => {
                hoverSubMenu(false);
              }}
            >
              {subList.map((item, index) => (
                <div
                  className="flex justify-between aligns-center App-header-dropdown-menu"
                  onClick={() => {
                    item.url ? click(true, item.url) : click(false, item.path);
                  }}
                  key={index}
                >
                  <div className="flex aligns-center">
                    {item.icon && <img src={item.icon} alt="fire" className="icon-sm"></img>}

                    <div className="ml-10">
                      <div>{item.title}</div>
                      <div className="App-header-dropdown-subtitle">{item.subTitle}</div>
                    </div>
                  </div>
                  <div className="ml-30" style={{ color: "rgb(255,255,255,0.5)", fontWeight: "bold" }}>
                    {">"}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
