import { FiX } from "react-icons/fi";
import logoImg from "img/logo-withname-white.png";
import { t } from "@lingui/macro";

import "./Header.css";
import { Link } from "react-router-dom";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { HeaderLink } from "./HeaderLink";
import { HeaderDropdownList } from "./HeaderDropdownList";
import { isFantomTestnet } from "config/env";
import { useChainId } from "lib/chains";

type Props = {
  small?: boolean;
  clickCloseIcon?: () => void;
  redirectPopupTimestamp: number;
  showRedirectModal: (to: string) => void;
};

type HomeLink = { label: string; link: string; isHomeLink?: boolean | false };

const HOME_MENUS: HomeLink[] = [
  {
    label: t`Dashboard`,
    isHomeLink: true,
    link: "/dashboard",
  },
  {
    label: t`Markets`,
    isHomeLink: true,
    link: "/markets",
  },
  {
    label: t`Earn`,
    isHomeLink: true,
    link: "/earn",
  },
  // {
  //   label: t`Buy`,
  //   isHomeLink: true,
  //   link: "/buy",
  // },
  {
    label: t`DAO`,
    isHomeLink: true,
    link: "/dao",
  },
  {
    label: t`Auction`,
    isHomeLink: true,
    link: "/auction",
  },
  // {
  //   label: t`Voting`,
  //   link: "https://snapshot.org/#/dip.eth",
  // },
  {
    label: t`Docs`,
    // link: "https://dip.gitbook.io/dip/",
    isHomeLink: true,
    link: "/docs",
  },
];

export function HomeHeaderLinks({ small, clickCloseIcon, redirectPopupTimestamp, showRedirectModal }: Props) {
  const { chainId } = useChainId();
  return (
    <div className="App-header-links">
      {small && (
        <div className="App-header-links-header">
          <Link className="App-header-link-main" to="/">
            <img src={logoImg} alt="DIP Logo" />
          </Link>
          <div
            className="App-header-menu-icon-block mobile-cross-menu"
            onClick={() => clickCloseIcon && clickCloseIcon()}
          >
            <FiX className="App-header-menu-icon" />
          </div>
        </div>
      )}
      {/* {HOME_MENUS.map(({ link, label, isHomeLink = false }) => {
        return (
          <div key={label} className="App-header-link-container">
            {isHomeLink ? (
              <HeaderLink
                to={link}
                redirectPopupTimestamp={redirectPopupTimestamp}
                showRedirectModal={showRedirectModal}
              >
                {label}
              </HeaderLink>
            ) : (
              <ExternalLink href={link}>{label}</ExternalLink>
            )}
          </div>
        );
      })} */}
      <div className="App-header-link-container">
        <HeaderLink
          to="/dashboard"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          <>Dashboard</>
        </HeaderLink>
      </div>
      <HeaderDropdownList
        small={small}
        isHomeLink={false}
        redirectPopupTimestamp={redirectPopupTimestamp}
        showRedirectModal={showRedirectModal}
        mainTitle={"Markets"}
        subList={
          isFantomTestnet(chainId)
            ? [
              {
                title: "Leveraged Trading",
                subTitle: "Up to 50x Leverage",
                icon: require("img/ic_leveraged.png"),
                path: "/markets",
              },
              {
                title: "Spot",
                subTitle: "Buy and Sell with advance tools",
                icon: require("img/ic_swap_trade.png"),
                path: "/spot",
              },
            ]
            : [
              {
                title: "Leveraged Trading",
                subTitle: "Up to 50x Leverage",
                icon: require("img/ic_leveraged.png"),
                path: "/markets",
              },
              {
                title: "Spot",
                subTitle: "Buy and Sell with advance tools",
                icon: require("img/ic_swap_trade.png"),
                path: "/spot",
              },
              {
                title: "Buy DIP/DGT",
                subTitle: "How to buy DIP/DGT",
                icon: require("img/ic_buy.png"),
                path: "/buy",
              },
            ]
        }
      />

      <HeaderDropdownList
        small={small}
        isHomeLink={false}
        redirectPopupTimestamp={redirectPopupTimestamp}
        showRedirectModal={showRedirectModal}
        mainTitle={"Earn"}
        subList={[
          {
            title: "Earn",
            subTitle: "Provide liquidity to earn Real Yield",
            icon: require("img/ic_earn.png"),
            path: "/earn",
          },
          {
            title: "Staking",
            subTitle: "Stake DIP or DGT to earn protocol revenue",
            icon: require("img/ic_stake.png"),
            path: "/staking",
          },
          // {
          //   title: "Liquidity Bootstrapping",
          //   subTitle: "Earn high early returns",
          //   icon: require("img/ic_lbp.png"),
          //   path: "/liquiditybootstrapping",
          // },
        ]}
      />

      <div className="App-header-link-container">
        <HeaderLink to="/dao" redirectPopupTimestamp={redirectPopupTimestamp} showRedirectModal={showRedirectModal}>
          DAO
        </HeaderLink>
      </div>
      <HeaderDropdownList
        small={small}
        isHomeLink={false}
        redirectPopupTimestamp={redirectPopupTimestamp}
        showRedirectModal={showRedirectModal}
        mainTitle={"Rewards"}
        subList={[
          {
            title: "Trade Mining Program",
            subTitle: "Trade and earn 100,000 DIP per day",
            icon: require("img/ic_tradingmining.png"),
            path: "/tradingmining",
          },
          {
            title: "Referral Program",
            subTitle: "Earn up to 70% of fees",
            icon: require("img/ic_referral.png"),
            path: "/referrals",
          },

          // {
          //   title: "AirDrop",
          //   subTitle: "Airdrop USDC/DIP rewards for users",
          //   icon: require("img/ic_airdrop.png"),
          //   path: "/trading/contest/rewards",
          // },
        ]}
      />
      <HeaderDropdownList
        small={small}
        isHomeLink={false}
        redirectPopupTimestamp={redirectPopupTimestamp}
        showRedirectModal={showRedirectModal}
        mainTitle={"Ecosystem"}
        subList={
          isFantomTestnet(chainId)
            ? [
              {
                title: "Faucet",
                subTitle: "Claim $100 USDT",
                icon: require("img/ic_faucet.png"),
                path: "/faucet",
              },
              {
                title: "Leaderboard",
                subTitle: "DIP Leaderboard",
                icon: require("img/ic_leaderboard.png"),
                url: "https://leaderboard.dip.exchange",
              },
              {
                title: "Bridge",
                subTitle: "Bridge assets from or to BASE",
                icon: require("img/ic_bridge.png"),
                path: "/bridge",
              },
              {
                title: "Docs",
                subTitle: "Learn about DIP Exchange",
                icon: require("img/ic_doc.png"),
                url: "https://docs.dip.exchange/",
              },
              // {
              //   title: "Forum",
              //   subTitle: "Learn about DIP Exchange",
              //   icon: require("img/ic_forum.png"),
              //   url: "https://forum.testnet.dip.exchange/",
              // },
            ]
            : [
              {
                title: "Auction",
                subTitle: "Bid for DIP and DGT",
                icon: require("img/ic_auction.png"),
                url: "https://app.bounce.finance/launchpad/dip_exchange",
              },
              {
                title: "Leaderboard",
                subTitle: "DIP Leaderboard",
                icon: require("img/ic_leaderboard.png"),
                url: "https://leaderboard.dip.exchange",
              },
              {
                title: "Bridge",
                subTitle: "Bridge assets from or to BASE",
                icon: require("img/ic_bridge.png"),
                path: "/bridge",
              },
              {
                title: "Docs",
                subTitle: "Learn about DIP Exchange",
                icon: require("img/ic_doc.png"),
                url: "https://docs.dip.exchange/",
              },
              {
                title: "Forum",
                subTitle: "Share your ideas",
                icon: require("img/ic_forum.png"),
                url: "https://forum.dip.exchange/",
              },
            ]
        }
      />
    </div>
  );
}
