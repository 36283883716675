import React from "react";
import cx from "classnames";
import LowRiskIcon from "img/ic_fire_green.png";
import HighRiskIcon from "img/ic_fire_red.png";
export default function RiskTip({ type }) {
  const lowTip = type === 1;
  const highTip = type === 3;
  return (
    <div className={cx("risk-tip flex aligns-center", { "risk-tip-low": lowTip, "risk-tip-high": highTip })}>
      <img src={highTip ? HighRiskIcon : LowRiskIcon} alt="risk tip" className="icon-xs"></img>
      {highTip ? "High Risk" : "Low Risk"}
    </div>
  );
}
