import { formatAmount, formatMoney } from "lib/numbers";

const dipInitPrice = 0.005;
const defaultDecimals = 18;
const poolPerSecondReward = 0.41666666;
export function getPoolData(res, index, lpPrice, dipPrice) {
  if (!res || res.length < 1) return;
  if (!lpPrice || lpPrice == 0) lpPrice = 1;
  dipPrice = !dipPrice ? dipInitPrice : dipPrice;
  const totalAmount = formatAmount(res, defaultDecimals, 4);
  const totalValue = lpPrice * totalAmount;

  // console.log(
  //   totalValue,
  //   poolPerSecondReward,
  //   dipPrice * poolPerSecondReward * getAllocPoint(index) * 60 * 60 * 24 * 365,
  //   poolPerSecondReward * getAllocPoint(index) * 60 * 60 * 24
  // );
  return {
    totalAmount: formatMoney(totalAmount, 4, true),
    totalValue: formatMoney(totalValue, 2, true),
    lpPrice: lpPrice,
    apr:
      parseFloat(totalValue) > 0
        ? (
            ((poolPerSecondReward * getAllocPoint(index) * 60 * 60 * 24 * 365 * parseFloat(dipPrice)) /
              parseFloat(totalValue)) *
            100
          ).toFixed(2)
        : "---",
  };
}

function getAllocPoint(index) {
  if (index === 0 || index === 1) {
    return 0.167;
  } else if (index === 2) {
    return 0.333;
  } else if (index === 3) {
    return 0.333;
  } else {
    return 0.333;
  }
}
export function getUserInfoData(res) {
  if (!res || res.length < 1) return;
  return {
    amount: formatAmount(res[0], defaultDecimals),
    boostedAmount: formatAmount(res[1], defaultDecimals),
    rewardDebt: formatAmount(res[2], defaultDecimals),
  };
}
